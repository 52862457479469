.accordion {
  display: flex;
  flex-flow: column nowrap;
}
.accordion__summary {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  list-style: none;
  gap: var(--tds-size-base);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.accordion__summary > h4 {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accordion__summary > span {
  margin-left: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.accordion .ReactCollapse--collapse {
  transition: height 500ms;
}
.accordion .ReactCollapse--content {
  padding-top: var(--tds-size-2x);
}
