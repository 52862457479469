/* Hide scrollbars */
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}
.tds-scrim--black {
  background-color: var(--tds-color--black);
}
.checkbox,
.radio {
  display: none;
}
:root {
  --widget-height: calc(100vh - 66px);
  --tds-base-text-color: var(--tds-color--grey30);
  --tds-base-line_height: var(--tds-line-height-30);
  --tds-base-font_size: var(--tds-font-size-30);
  --tds-font-family-base: var(--tds-font-combined);
  --widget-height: calc(100vh - var(--tds-shell-header-height));
  --bezier: cubic-bezier(0.5, 0, 0, 0.75);
  --tds-form-item-margin_bottom: 16px;
    /* stylelint-disable-next-line */
  --tds-icon--transform: ;
  --gallery-fixed-height-mobile: 210px;
  --gallery-fixed-height-tablet: 315px;
  --tds-size-1xhalf: calc(var(--tds-size-1x) + var(--tds-size-half));
  --tds-o-success-color: #12bb00;
  --tds-o-gradient-bg-color: rgba(255, 255, 255, 1);
  --tds-o-gradient-color: var(--tds-color--white);
  --tds-o-option-name-color: var(--tds-color-grey-10);
  --coin-border-radius-asset-btn: var(--tds-size-half);
  --coin-border-radius-asset-btn-img: 3px;
}
.tds-theme--replicant-v2 {
  --tds-o-gradient-bg-color: rgb(34, 34, 34, 1);
}
.body-fixed {
  position: fixed;
  width: 100%;
}
/* PayX START */
.delivery-zip--container,
.payment-react iframe {
  width: calc(100% + 8px) !important;
  margin-left: -4px;
  margin-right: 4px;
}
[dir=rtl] .payment-react iframe {
  margin-left: 4px;
  margin-right: -4px;
}
.tesla--payment-react .tds-form-input-wrap,
.tesla--payment-react .tds-o-form-input-wrap,
.tesla--payment-react .tds-o-form-phone-input-wrap {
  background-color: var(--tds-color--grey70);
}
/* Remove border from PayX iframe */
.tesla--payment-react--form {
  border: none;
  display: block;
}
/* Remove padding for hidden buttons on payment */
.two-button-spacer .tds-btn {
  padding: 0;
}
.tesla--payment-plugin-context-form--save-payment-legal {
  --tds-text--color: var(--tds-color--grey30);
  color: var(--tds-text--color);
  font-size: var(--tds-font-size-20);
  line-height: var(--tds-line-height-10);
}
/* PayX END */
/* Z index to prevent the footer layout from blocking the aside */
.config-aside {
  z-index: 1;
}
/* Payment Page Styles START */
.payment-page .config-aside:after,
.payment-page .config-aside:before,
.earlydelivery-page .config-aside:after,
.earlydelivery-page .config-aside:before {
  display: none;
}
.payment-page .scroll-snap,
.earlydelivery-page .scroll-snap {
  overflow-y: initial;
}
.payment-page .cf-h2-title,
.earlydelivery-page .cf-h2-title {
  padding-block-start: 0;
  padding-block-end: 0;
  padding-bottom: 0;
}
/* Apple Pay Button */
.apple_pay {
  display: flex;
  flex-wrap: nowrap;
}
.apple_pay .apple_pay-button {
  -webkit-appearance: none;
  -apple-pay-button-type: buy;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-size: 100% calc(60% + 2px);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: background-color 0.15s;
}
.btn-applepay {
  display: inline-block !important;
  font-size: 0 !important;
}
.payment-options .tds-btn {
  width: 100%;
}
/* Payment Page Styles END */
@media (max-width: 1025px) and (orientation: portrait) {
  body.no-scroll-cfg {
    overflow: hidden;
  }
  body:not(.coin-reloaded) [data-group-id=PAINT].group-section--container {
    padding-bottom: var(--tds-size-5x);
  }
  /* .coin-reloaded .group-section--container {
    padding: var(--tds-size-4x) 0;
  } */
}
.tds--hide_on_mobile {
  display: none !important;
}

@media (min-width: 1025px) or (orientation: landscape) {
  .coin-reloaded.coin-order .group-container .aside-section:first-child {
      margin-block-start: 8vh;
    }
  .tds--hide_on_mobile {
    display: inherit !important;
  }
  html {
    height: 100vh;
    height: -webkit-fill-available;
    overflow: auto;
  }
  body {
    height: 100vh;
    height: -webkit-fill-available;
    overflow-y: visible;
    scrollbar-width: none;
  }
  .tds-theme--enterprise .coin-reloaded.coin-order .main-page:not(.payment-page) .group-container .aside-section:first-child {
    margin-block-start: 0;
  }
  .coin-order .group-container .aside-section:last-child {
    margin-block-end: 25vh;
  }
  .inventory-group-section .aside-section:not(:last-child),
  .group-section .aside-section:not(:last-child) {
    padding: 0;
  }
  .inventory-group-section .delivery-contact--section,
  .inventory-group-section .aside-section:not(:last-child) > :first-child:not([data-group-id="EditDesignLink:main.PAYMENT"], [data-group-id=REVIEW_ORDER], [data-group-id="FOUNDATION_SERIES"], [data-group-id="OPTIONS"]),
  .group-section .delivery-contact--section,
  .group-section .aside-section:not(:last-child) > :first-child:not([data-group-id="EditDesignLink:main.PAYMENT"], [data-group-id=REVIEW_ORDER], [data-group-id="FOUNDATION_SERIES"],[data-group-id="OPTIONS"]) {
    padding-block-end: 10vh;
    padding-bottom: 10vh;
  }
  .inventory-group-section .aside-section .padding-block-start--10,
  .group-section .aside-section .padding-block-start--10 {
    padding-block-start: 10vh;
    padding-top: 10vh;
  }
  .cf-action-target:empty,
  .inventory-group-section .aside-section:has(.group-section--container:empty),
  .group-section .aside-section:has(.group-section--container:empty) {
    display: none;
  }
  /* Sets overall space around the edge of the logo/links to be 48px, per Design */
  .tds-site-header.tds-o-increase-inline-padding {
    margin: 0 var(--tds-size-base);
  }
  /* Overrides on payment page */
  .payment-page .aside-section,
  .earlydelivery-page .aside-section {
    padding-block-end: 0;
    padding-bottom: 0;
  }
  body:not(.coin-reloaded) .payment-page .aside-section:last-child,
  body:not(.coin-reloaded) .earlydelivery-page .aside-section:last-child {
    padding-block-end: 10vh;
    padding-bottom: 10vh;
  }
  .tds-theme--enterprise.cyberpunk .payment-page .aside-section:last-child,
  .tds-theme--enterprise.cyberpunk .earlydelivery-page .aside-section:last-child {
    padding-block-end: 0;
    padding-bottom: 0;
  }
  .feature-highlight .feature-title {
    margin-top: var(--tds-size-base);
  }
  .coin-reloaded .cf-summary_container {
    scroll-margin-top: var(--tds-site-header--height);
  }
}
/* Navigation selected tab override */
.tds-tabs.tds-tabs--toggle nav .tds-tab[aria-selected=true] {
  color: var(--tds-color--black);
  background-color: var(--tds-color--white);
  box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
/* Asset Loader V2 START */
.coin-shell-container img,
.coin-shell-container svg:not(.tds-icon) {
  transform: translateZ(0);
}
.asset-loader-2 {
  transition: opacity 1s var(--bezier), transform 1s var(--bezier);
  image-rendering: -webkit-optimize-contrast;
}
.asset-loader--play-video:hover {
  background-color: black;
}
.asset-loader--play-video:hover image {
  opacity: 0.6;
}
/* Asset Loader V2 END*/
/* Main scroll container */
.coin-shell-container section {
  transition: opacity 1s var(--bezier), transform 1s var(--bezier);
}
/* Main Sections */
.scroll-snap {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scrollbar-width: none;
}
.observer-placeholder {
  overflow: hidden;
  position: relative;
}

.observer-placeholder:has(.asset-loader-2-video-inline) {
  aspect-ratio: 16/9;
}
.option-visualization .observer-placeholder {
  display: flex;
  align-items: center;
}
.image-visualization {
  display: flex;
  align-items: center;
}
body:not(.coin-reloaded) .option-widget {
  position: relative;
}
.option-visualization .content,
.option-widget .content {
  height: 100%;
  width: 100%;
}
.content-asset--full,
.content-asset--img {
  width: 100%;
  height: 100%;
}
.cf-nav--btn {
  font-size: var(--tds-font-size-20);
}
.cf-nav-icon {
  background: var(--tds-color--grey70);
  border-radius: 100%;
}
.cf-nav-icon path.arrow {
  transform: scale(1.25);
  transform-origin: center;
}
.group-main-content--full-asset {
  overflow: hidden;
}
/* Header Styles */
.site-header-container {
  width: 100%;
  z-index: 3;
  position: sticky;
  top: 0;
  transition: z-index 0.5s step-end;
}
.coin-reloaded.coin-navup .site-header-container {
  top: calc(-1.1 * var(--tds-shell-header-height));
}
.tds-modal--is-open .site-header-container:has(+ .earlydelivery-page) {
  z-index: 12;
  transition: z-index 0.5s step-start;
}
.tds-site-header {
  background: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(48px);
          backdrop-filter: blur(48px);
}
.tds-site-header-modal {
  height: 100vh !important;
  height: 100dvh !important;
}
.tds-theme--enterprise .tds-site-header {
  background: var(--tds-color--white);
}
.tds-theme--enterprise.tds-scrim--cyberpunk .tds-site-header {
  background: var(--tds-color--black);
}
.cf-shell-content {
  max-width: 100vw;
}
.cf-header-wrapper:before,
.tds-o-header--wrapper::before {
  content: "";
  display: block;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(48px);
          backdrop-filter: blur(48px);
}
.tds-theme--enterprise .cf-header-wrapper:before,
.tds-theme--enterprise .tds-o-header--wrapper::before {
  background: var(--tds-color--white);
}
.delivery-date-header {
  position: absolute;
  z-index: 0;
  opacity: 0;
  top: 0;
  transform: translateY(0);
  width: 29.7rem;
  background: var(--tds-color--white);
  right: 0;
  text-align: center;
  height: 54px;
}
html[dir=rtl] .delivery-date-header {
  left: 0;
  right: unset;
}
@media (max-width: 1024px) {
  html[dir=rtl] .delivery-date-header {
    left: unset;
  }
}
.delivery-date-header .deliveryZipCode {
  margin-top: 0;
  padding-top: 0;
}
.coin-reloaded .cf-asset-wrapper--fixed {
  position: sticky;
  background-color: var(--tds-o-gradient-color);
  width: 100%;
  z-index: 2;
  top: var(--tds-shell-header-height);
  height: var(--gallery-fixed-height-mobile);
  transition: top 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 10px 10px -5px var(--tds-o-gradient-bg-color);
}
.tds-theme--dk .coin-reloaded .cf-asset-wrapper--fixed,
.wechat-mini-program-environment .cf-asset-wrapper--fixed
{
  top: 0;
}
@media (max-width: 1024px) {
  .delivery-date-header {
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    height: 56px;
    background: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: blur(48px);
            backdrop-filter: blur(48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: transform 1s var(--bezier) 0s;
    transform: translate3d(0, -100%, 0);
  }
}
.delivery-date-header__show {
  opacity: 1;
  z-index: 11;
}
@media (max-width: 1024px) {
  .delivery-date-header__show {
    transform: translate3d(0, 0, 0);
  }
}
.sticky-estimated-delivery-date {
  transform: translate(0, var(--tds-size-2x));
}
.view-available-inventory {
  padding: var(--tds-size-3x);
  text-align: center;
}
.delivery-estimate-disclaimer {
  padding: var(--tds-size-3x);
  text-align: center;
}
/* TDS Overrides - TODO: Remove after TDS properly merges site-header with shell-masthead */
@media (max-width: 599px), (max-width: 899px) and (min-width: 600px), (max-width: 1199px) and (min-width: 900px) {
  .tds-site-nav--flyout,
  .tds-site-nav--flyout-backdrop {
     bottom: auto;
     height: 100%;
   }
  .sticky-estimated-delivery-date {
     transform: translate(0);
   }
 }
 
 @media (max-width: 1200px) and (min-width: 1024px) {
  .sticky-estimated-delivery-date {
     display: none;
   }
 }
 
@media (min-width: 1025px) {
  .option-widget {
    display: grid;
    grid-template: "main aside" 1fr/9fr minmax(342px, 1fr);
    grid-gap: 0 var(--tds-size-6x);
    padding-inline-end: var(--tds-size-6x);
    scroll-margin-top: var(--tds-shell-header-height);
    transition: none;
    scrollbar-width: none;
  }
}
@media (max-width: 1024px) and (orientation: landscape) {
  .option-widget {
    display: grid;
    grid-template: "main aside" 1fr/9fr minmax(342px, 1fr);
    grid-gap: 0 var(--tds-size-6x);
    padding-inline-end: var(--tds-size-2x);
    scroll-margin-top: var(--tds-shell-header-height);
    transition: none;
    scrollbar-width: none;
  }
}
@media (min-width: 1025px) or (orientation: landscape) {
  :root {
    --scroll-height: calc(100vh - var(--tds-shell-header-height));
    --nav-button-height: 120px;
  }
  .tds-shell {
    display: flex;
    flex-direction: column;
  }
  .cf-header-wrapper,
  .tds-o-header--wrapper {
    position: absolute;
    width: 100%;
    z-index: 15;
  }
  body:not(.coin-reloaded) .scroll-container .group-section > :nth-child(1) {
    padding-top: var(--tds-size-11x);
  }

  .tds-theme--enterprise.cyberpunk .scroll-container .group-section > :nth-child(1) {
    padding-top: 0;
  }

  .scroll-container {
    margin-top: calc(0px - var(--tds-site-header--height));
  }

  :has(.tcl-banner) .scroll-container {
    margin-top: calc(0px - var(--tds-shell-header-height) - var(--tds-site-header--height));
  }

  .tds-theme--enterprise :has(.tcl-banner) .scroll-container {
    margin-top: calc(0px - var(--tds-shell-header-height))
  }

  .tds-theme--dk .scroll-container {
    margin-top: 0;
  }

  .tds-theme--dk :has(.tcl-banner) .scroll-container {
    margin-top: calc(0px - var(--tds-site-header--height));
  }

  .cf-asset-wrapper {
    grid-area: main;
    max-height: 100%;
    position: sticky;
    overflow: hidden;
    height: 100vh;
    top: 0;
  }

  .tds-theme--normal :has(.tcl-banner) .cf-layout > .option-widget--container {
    padding-top: var(--tds-size--4x);
  }
    
  .cf-layout {
    height: 100%;
  }
  .cf-layout > .option-widget--container {
    grid-area: aside;
    -webkit-overflow-scrolling: touch;
    overflow: visible !important;
  }
  .cf-layout .option-widget--container .tds-o-form-phone-input-wrap .tds-tooltip {
    max-width: calc(342px - 2 * var(--tds-size-2x));
  }
  .option-widget--container > .group-container {
    z-index: inherit;
  }
  .option-widget--container > .group-container:hover {
    z-index: 30;
  }
  .option-widget--container > legend {
    inline-size: 100%;
  }
  .videoContent--container .text-loader--content {
    max-width: none;
  }
  .main-page--desktop .videoContent--container {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: 20%;
  }
  .cf-nav-btn {
    position: absolute;
    right: var(--tds-size-6x);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    cursor: pointer;
    height: var(--nav-button-height);
    width: 342px;
    background: transparent;
    z-index: 12;
  }
  .cf-nav-btn:focus {
    outline: none;
  }
  .payment-page .cf-nav-btn {
    display: none;
  }
  .cf-nav--prev {
    top: 48px;
  }
  .cf-nav--next {
    bottom: 0;
  }
  .child-group--option_details.child-group--swatch.child-group--compact .group--options_asset {
    width: 50px !important;
    height: 50px !important;
  }
  .child-group--swatch:has(> :last-child:nth-child(7)) {
    padding-left: var(--tds-size--4x);
    padding-right: var(--tds-size--4x);
  }
  .child-group--option_details.child-group--swatch.child-group--compact .group--options_asset image {
    width: 36px !important;
    height: 36px !important;
  }
}
.tds-btn--small:not(.tcl-banner__button) {
  font-size: var(--tds-btn--font-size);
  --tds-height--pill: var(--tds-size-4x);
  padding: var(--tds-size-half) var(--tds-size-3x);
}
@media (min-width: 600px) {
  .tds-btn--small {
    min-width: 145px;
    max-width: 170px;
  }
  [dir=rtl] .tds-btn {
    right: auto;
  }
  .i18n-region-eu:not(.i18n-language-en) .tds-btn--small {
    max-width: 160px;
  }
  .coin-reloaded.i18n-region-eu:not(.i18n-language-en) .tds-btn--small {
    max-width: fit-content;
  }
}
@media (min-width: 600px) {
  .modal-container--financial_modal.tds-o-mega-modal::after {
    content: "";
    display: block;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: var(--tds-size-4x);
    box-shadow: 0px -10px 10px var(--tds-o-gradient-bg-color);
    background-color: var(--tds-theme-background-dialog);
  }
}
@media (min-width: 500px) and (max-width: 1024px) and (orientation: portrait) {
  .coin-reloaded .cf-asset-wrapper--fixed {
    height: var(--gallery-fixed-height-tablet);
  }
}
/* Our tablet starts at 1024px for iPad portrait view */
@media (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
  .coin-shell-container {
    min-block-size: fit-content;
  }
  .tds--hide_on_desktop {
    display: block;
  }
  .tds--is_invisible_on_desktop {
    display: none;
  }
  .option-visualization {
    height: auto;
    border-radius: 0;
  }
  .option-widget--container,
  .cf-content_container {
    padding-left: calc(var(--tds-size-4x) + var(--tds-size-half)) !important;
    padding-right: calc(var(--tds-size-4x) + var(--tds-size-half)) !important;
  }
  .scroll-snap {
    scroll-snap-type: none;
  }
  .cf-action-target > .modal-trigger + .modal-trigger {
    margin-left: var(--tds-size-2x);
  }
  .aside-section:last-child {
    padding-block-end: 110px;
    padding-bottom: 110px;
  }
  .videoContent--container .text-loader--content {
    text-align: center;
  }
}
/* TDS Add-ons START */
.tds-o-list--unstyled {
  list-style-type: none;
}
.tds-flex--justify-center {
  justify-content: center;
}
.tds-flex--justify-flex-end {
  justify-content: flex-end;
}
.tds-flex--justify-flex-start {
  justify-content: flex-start;
}
.tds-flex--space-between {
  justify-content: space-between;
}
.tds-flex--space-around {
  justify-content: space-around;
}
.tds-flex--auto {
  flex: auto;
}
.tds-display--inline {
  display: inline;
}
.tds-display--block {
  display: block;
}
.tds--vertical_padding-10 {
  padding: 10px 0 10px 0;
}
.tds--no_vertical_padding {
  padding-top: 0 !important;
  padding-block-start: 0 !important;
  padding-bottom: 0 !important;
  padding-block-end: 0 !important;
}
.tds--vertical_padding--6x {
  padding-block-start: var(--tds-size-6x);
  padding-block-end: var(--tds-size-6x);
  padding-bottom: var(--tds-size-6x);
}
.tds--border-bottom_grey50 {
  border-bottom: 1px solid var(--tds-color--grey50);
}
.tds--border_grey50 {
  border: 1px solid var(--tds-color--grey50);
}
.tds-o-vertical_padding-top--half {
  padding-block-start: var(--tds-size-half) !important;
  padding-top: var(--tds-size-half) !important;
}
.tds-o-vertical_padding-top--1x {
  padding-block-start: var(--tds-size-base) !important;
  padding-top: var(--tds-size-base) !important;
}
.tds-o-vertical_padding-top {
  padding-block-start: var(--tds-size-2x) !important;
  padding-top: var(--tds-size-2x) !important;
}
.tds-o-vertical_padding-top--2x {
  padding-block-start: var(--tds-size-2x) !important;
  padding-top: var(--tds-size-2x) !important;
}
.tds-o-vertical_padding-top--3x {
  padding-block-start: var(--tds-size-3x) !important;
  padding-top: var(--tds-size-3x) !important;
}
.tds-o-vertical_padding-top--4x {
  padding-block-start: var(--tds-size-4x) !important;
  padding-top: var(--tds-size-4x) !important;
}
.tds-o-vertical_padding--1x {
  padding-block-start: var(--tds-size-base);
  padding-block-end: var(--tds-size-base);
  padding-top: var(--tds-size-base);
  padding-bottom: var(--tds-size-base);
}
.tds--vertical_padding-top {
  padding-block-start: var(--tds-size-2x) !important;
  padding-top: var(--tds-size-2x) !important;
}
.tds--vertical_padding-bottom--1x {
  padding-block-end: var(--tds-size-base) !important;
  padding-bottom: var(--tds-size-base) !important;
}
.tds--vertical_padding-bottom {
  padding-block-end: var(--tds-size-2x) !important;
  padding-bottom: var(--tds-size-2x) !important;
}
.tds--vertical_padding-bottom--3x {
  padding-block-end: var(--tds-size-3x) !important;
  padding-bottom: var(--tds-size-3x) !important;
}
.tds--vertical_padding-bottom--4x {
  padding-block-end: var(--tds-size-4x) !important;
  padding-bottom: var(--tds-size-4x) !important;
}
.tds-o-horizontal_padding-24 {
  padding: 0 var(--tds-size-3x);
}
.tds-o-no-margin {
  margin: 0 !important;
}
.tds-o-margin-top {
  margin-block-start: var(--tds-size-base) !important;
  margin-top: var(--tds-size-base) !important;
}
.tds-o-margin-top--2x {
  margin-block-start: var(--tds-size-2x) !important;
  margin-top: var(--tds-size-2x) !important;
}
.tds-o-margin-top--3x {
  margin-top: var(--tds-size-3x);
}
.tds-o-margin-top--8x {
  margin-top: var(--tds-size-8x);
}
.feature-group--items .tds-flex-gutters.feature-group--line-item {
  margin-bottom: 7px;
}
.feature-group--items .text-loader--main_title {
  color: var(--tds-color--black);
  font-family: var(--tds-font-combined--medium);
  font-weight: 500;
}
.tds-text_color--ff {
  color: var(--tds-color--white);
}
.tds-text_color--00 {
  color: var(--tds-color--black);
}
.tds-o-no_padding_top {
  padding-block-start: 0 !important;
}
.tds-o-no_padding_bottom {
  -webkit-padding-end: 0 !important;
  padding-block-end: 0 !important;
}
.tds--horizontal_margin-5 {
  margin: 0 5px;
}
.tds--padding_top-8 {
  padding-block-start: var(--tds-size-1x) !important;
}
.tds--padding_top-16 {
  padding-block-start: var(--tds-size-2x) !important;
}
.tds--padding_left-16 {
  padding-left: var(--tds-size-2x);
  padding-inline-start: var(--tds-size-2x);;
}
.tds--padding_inline-36 {
  padding-inline: calc(var(--tds-size-4x) + var(--tds-size-half)) !important;
}

.tds--padding-left-hidden-point {
  padding-left: calc(var(--tds-size-3x) + var(--tds-size-base) / 2);
}
.tds-o-padding_top-12 {
  padding-block-start: calc(var(--tds-size-1x) + var(--tds-size-base)) !important;
}
.tds-o-padding_bottom-8 {
  padding-block-end: var(--tds-size-1x) !important;
}
.tds-o-padding_bottom-12 {
  padding-block-end: calc(var(--tds-size-1x) + var(--tds-size-base)) !important;
}
.tds-o-padding_bottom-16 {
  padding-block-end: var(--tds-size-2x) !important;
}
.tds-o-padding_bottom-24 {
  padding-block-end: var(--tds-size-3x) !important;
}
.tds-o-padding_bottom-32 {
  padding-block-end: var(--tds-size-4x) !important;
}
.tds-o-horizontal_padding-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.tds-o-margin_bottom-half {
  margin-block-end: var(--tds-size-half) !important;
}
.tds-o-margin_bottom {
  margin-block-end: var(--tds-size-base) !important;
}
.tds-o-text_color--20 {
  color: var(--tds-color--grey20);
}
.tds-o-padding-large-3x {
  padding: var(--tds-size-3x);
}
.tds-o-text_no-wrap {
  white-space: nowrap;
}
.tds--border_top {
  border-top: 1px solid var(--tds-color--grey50);
}
.tds-o-margin_right-16 {
  margin-inline-end: var(--tds-size-2x);
}
.tds-o--vertical_padding_with_border:before, .tds-o--vertical_padding_with_border:after {
  margin-block-start: var(--tds-size-2x) !important;
  content: " ";
  width: 100%;
  background: var(--tds-color--grey50);
  margin-block-end: var(--tds-size-2x) !important;
  height: 1px;
  display: block;
}
.tds-o-margin_bottom-16 {
  margin-bottom: var(--tds-size-2x);
}
.tds--no_padding {
  -webkit-padding-after: 0;
  padding-block-start: 0;
  -webkit-padding-before: 0;
  padding-block-end: 0;
  padding-bottom: 0;
}
.tds-theme--replicant dialog#tds-main-modal.modal-container--scrollable:after {
  height: auto;
  background: var(--tds-color--black);
}
@media (max-width: 599px) {
  dialog#tds-main-modal.modal-container--scrollable.tds-modal[open] {
    max-height: calc(100vh - var(--tds-size-12x));
  }
  .i18n-country-FR .tds-btn--small {
    max-width: 160px;
    white-space: normal;
  }
}
.modifier--tds-link a {
  border: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  text-decoration: none;
  text-align: initial;
  color: var(--tds-color--grey20);
  font-family: var(--tds-font-combined);
  transition: box-shadow 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75), color 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}
.modifier--tds-link-primary a,
.modifier--tds-link-primary .inspection-link {
  color: var(--tds-color--blue30);
  font-family: var(--tds-font-combined--medium);
  box-shadow: none;
}
.tds-banner .tds-status_msg {
  margin: 0 auto;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .tds-banner .tds-status_msg {
    max-width: 576px;
  }

  .coin-reloaded .cf-summary_container {
    scroll-margin-top: var(--gallery-fixed-height-tablet);
  }
}
.coin-shell-container .tds-modal-content .tds-status_msg > .tds-icon {
  margin-inline-end: var(--tds-size-base) !important;
}
/* TDS Add-ons END */
.footer-modal-trigger {
  background: var(--tds-color--white);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  border-radius: 100%;
  transition: filter 0.33s ease;
  overflow: visible;
  width: var(--tds-size-4x);
  min-width: var(--tds-size-4x);
  height: var(--tds-size-4x);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-modal-trigger:hover {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}
button.tds-link {
  line-height: normal;
}
.group-option--detail-container_name {
  font-family: var(--tds-sans-font_stack--medium);
  color: var(--tds-color--black);
  margin-inline-end: 10px;
  white-space: nowrap;
}
.feature-group--items .tds-flex-gutters.feature-group--line-item {
  margin-bottom: 7px;
}
.group--option-disclaimer > span + .tds-link {
  margin-left: 5px;
}
/* Option Details - Option Asset / Option cardAddon END */
/* Animations */
body:not(.coin-reloaded) .payment-page .option-widget--container > :nth-child(1) {
  animation: animate-in 0.75s var(--bezier) !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
}
body:not(.coin-reloaded) .overview-page .option-widget--container,
body:not(.coin-reloaded) .payment-page .option-widget--container > :nth-child(2) {
  animation: animate-in 1s var(--bezier) !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
}
@keyframes animate-in {
  0% {
    opacity: 0;
    transform: translateY(var(--tds-size-3x));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.observer-placeholder:nth-child(2) .group--options_asset {
  transition-delay: 0.2s;
}
.observer-placeholder:nth-child(3) .group--options_asset {
  transition-delay: 0.4s;
}
.observer-placeholder:nth-child(4) .group--options_asset {
  transition-delay: 0.6s;
}
.observer-placeholder:nth-child(5) .group--options_asset {
  transition-delay: 0.8s;
}
.observer-placeholder:nth-child(6) .group--options_asset {
  transition-delay: 1s;
}
.assets-loader,
.inline-loader {
  position: absolute;
  z-index: -1;
}
.group--main-content--regular [id^=video-id] {
  min-width: 100%;
  max-width: 100%;
  transition: opacity 500ms;
  min-height: 100%;
}
.group--main-content--regular [id^=video-id].active {
  z-index: 1;
  opacity: 1;
  transition-delay: 400ms;
}
.selection-form .selection-btn {
  outline: none;
  text-transform: none;
  border: 1px solid var(--tds-color--grey50);
  color: var(--tds-color--grey30);
}
.selection-form .selection-btn.selected {
  color: var(--tds-color--grey10);
  border: 3px solid var(--tds-color--blue30);
}
.selection-form .selection-btn.tds-btn--outline:hover, .selection-form .selection-btn.tds-btn--outline:focus {
  background-color: var(--tds-color--white);
}
/* Action Target Group START */
.cf-action-target .observer-placeholder {
  min-width: 160px;
}
.cf-action-target__tablet{
  display: block;
  text-align: center;
}
/* Action Target Group END */
[role=button],
[role=link],
[role=tab],
[role=tabpanel] {
  cursor: pointer;
  outline: none;
}
.financing-options--container,
.financial--form-tab,
.text-loader--content,
.feature-highlight {
  outline: none;
}
.text-loader--content:not(input):focus-visible {
  outline: none;
}
.child-options--component:first-of-type .group--detail-container,
.child-options--component:first-of-type .child-group--selected_option_details {
  display: none;
}
.child-options--component .tds-btn:not(:first-child) {
  margin-right: 0;
}
.child-options--component .child-group--option_details {
  display: flex;
}
.child-group--option_details {
  display: block;
}
.tds-toggle--animate-zero:first-of-type {
  animation: slide-in 1s var(--bezier) !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
}
.tds-toggle--animate-zero:last-of-type {
  display: none;
}
.tds-toggle--animate-right:first-of-type {
  display: none;
}
.tds-toggle--animate-right:last-of-type {
  animation: slide-in 0.75s var(--bezier) !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
}
.child-options--component:last-child .child-options--components {
  flex: none;
  align-items: center;
}
.i18n-region-eu .child-options--component:last-child .child-options--components .tds-btn--medium {
  padding: 0 var(--tds-size);
}
.tds-theme--normal .i18n-language-en .cf-payment-overview__title {
  text-transform: capitalize;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(350px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (max-width: 599px) {
  .child-options--component {
    flex-flow: wrap;
  }
  .child-options--component:first-of-type .child-options--components {
    display: inline;
  }
}
.tds-checkbox-card {
  background-color: var(--tds-color--white);
  margin: 20px auto;
  width: 95%;
  border-radius: var(--tds-size-2x);
  box-shadow: 0 0 15px 2px var(--tds-color--grey60);
  display: block;
}
.tds-checkbox-card .tds-label--checkbox {
  display: contents;
}
.tds-checkbox-card .tds-label--checkbox p:first-child {
  margin: 0 var(--tds-size-4x);
}
.tds-checkbox-card .tds-checkboxes {
  padding: 10px var(--tds-checkboxes-horizontal_spacer);
}
.tds-checkbox-card .tds-checkboxes .tds-checkbox {
  margin-bottom: var(--tds-size-base);
  margin-top: var(--tds-size-base);
}
.child-group--selected_option_details .custom-content {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.coin-reloaded .child-group--selected_option_details .custom-content {
  flex-flow: column-reverse;
}
.child-group--selected_option_details .custom-content .group-option--recommended {
  flex-basis: 100%;
}
.child-group--selected_option_details .tds-flex-item {
  flex: initial;
}
.child-group--selected_option_details .group-option--detail-container_price {
  margin: 0 auto;
}
.child-group--selected_option_details .group-option--detail-container_price + .group-option--detail-container_price {
  padding-block-start: 0;
  font-size: var(--tds-font-size-20);
}
.has-extra-price {
  flex-flow: column;
}
.has-extra-price .group-option--price {
  padding-block-start: var(--tds-size-base);
  padding-block-end: var(--tds-size-base);
  flex-flow: column;
}
.formatted-price + .formatted-price {
  padding-block-start: 0;
  font-size: var(--tds-font-size-20);
}
.double-currency {
  font-size: var(--tds-font-size-20);
  font-weight: var(--tds-font-weight-regular);
}
span > .double-currency, th .double-currency {
  display: block;
}
th .double-currency {
  font-size: var(--tds-font-size-30);
}
li.double-currency {
  margin-block-start: calc(-1 * var(--tds-list-item--margin-block));
}
/* Asset/Circular Selector focus state */
:root {
  --tds-transition--box-shadow: box-shadow 0.33s ease;
  --tds-transition--background-color: background-color 0.33s ease;
}
.group--options_asset {
  border-radius: 100%;
  background-color: var(--tds-color--white);
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  transition: var(--tds-transition--background-color), var(--tds-transition--box-shadow);
}
.coin-reloaded .group--options_asset {
  transition: none;
}
.asset-loader-2-video-inline > video {
  width: 100%;
}
.coin-reloaded .asset-loader-2-video-inline {
  width: 100%;
  position: relative;
}
.group--options_asset.selected {
  box-shadow: inset 0 0 0 3px var(--tds-color--blue30);
}
.coin-reloaded .group--options_asset.selected {
  box-shadow: inset 0 0 0 1.03px var(--tds-color-grey-30);
  background: #EDEDED;
}
.dynamic_disclaimer--content {
  padding-top: var(--tds-size-2x);
}
.dynamic_disclaimer--content.padding-top--0 {
  padding-top: 0;
}
.custom-incentives--section .action-trigger--link:focus {
  outline: 1px dotted currentColor;
}
.group--options_block_disclaimer {
  padding-bottom: var(--tds-size-base);
}
.features-list--title {
  font-size: var(--tds-font-size-30);
}
.tds-text--body-large {
  font-size: var(--tds-font-size-40);
  line-height: var(--tds-line-height-40);
}
@media (max-width: 599px) {
  .tds-text--body-large {
    font-size: var(--tds-font-size-30);
    line-height: var(--tds-line-height-30);
  }
}
@media (max-width: 599px) {
  .child-group--selected_option_details .custom-content .group-option--price {
    order: 2;
  }
}
.group-upgrades--section {
  padding-top: var(--tds-size-6x);
}
.group-upgrades--section .text-loader--description {
  padding: 0;
  color: var(--tds-color--black);
  font-family: var(--tds-font-combined--medium);
}
.group-upgrades--section .text-loader--description,
.group-upgrades--section .text-loader--main_title {
  text-align: center;
}
.child-options--component {
  flex-direction: column;
}
.child-options--component .group--detail-container {
  display: flex;
  justify-content: center;
}
.modal-container--finance_container .tds-icon-trigger:focus-within,
[data-cfg-type=view-available-inventory]:focus {
  outline: 1px dotted currentColor;
}
.legal-disclaimer .modal-trigger-container button {
  display: inline;
  inline-size: auto;
  overflow-wrap: break-word;
}
[dir=rtl] .legal-disclaimer .tds-checkbox .tds-label {
  padding-left: 0;
  padding-right: var(--tds-size-4x);
}
.tds-theme--enterprise {
  /* EAP / FSD checkbox undoing tds-theme--enterprise styles without removing tds-theme--enterprise style
   * As removing that breaks a ton of stuff, design has changed its mind recently on enterprise look
   * vs back when we launched
   * START
   */
}
.tds-theme--enterprise .text-loader--content:not(.tds-icon, .tds-btn) {
  width: 100%;
}
.tds-theme--enterprise .tds-form-item--choice {
  grid-column-gap: var(--tds-size-base);
}
.tds-theme--enterprise .tds-form-label-wrap .tds-form-label-text {
  font-size: var(--tds-font-size-30);
}
.tds-theme--enterprise .cf-asset-wrapper,
.tds-theme--enterprise .cf-layout > .option-widget--container {
  padding-top: var(--tds-shell-header-height);
}
.tds-theme--enterprise .has-top-banner--hidden .main-page:not(.payment-page) .cf-asset-wrapper,
.tds-theme--enterprise .has-top-banner--hidden .main-page:not(.payment-page) .cf-layout > .option-widget--container {
  padding-top: calc(var(--tds-shell-header-height) - var(--coin-top-banner-height));
}

.tds-theme--enterprise .option-widget {
  grid-template: "main aside" 1fr/9fr minmax(432px, 1fr);
  padding-inline-end: 0;
}

.tds-theme--enterprise .option-widget.t4b-sidenav-enabled {
  grid-template: "sidenav main aside" 1fr/minmax(min-content, max-content) 9fr minmax(432px, 1fr);
}

.tds-theme--enterprise .option-widget--container .group-container {
  padding-inline-end: var(--tds-size-6x);
}
.tds-theme--enterprise .payment-page .cf-asset-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow: visible;
}
.tds-theme--enterprise .cf-asset-wrapper .aside-section {
  padding: 0;
}
.tds-theme--enterprise .gallery {
  top: 122px;
  height: calc(100% - 122px);
  padding-inline-start: var(--tds-size-6x);
}

.tds-theme--enterprise .t4b-sidenav-enabled .gallery {
  padding-inline-start: 0;
}

.tds-theme--enterprise .navigation--section {
  padding-top: var(--tds-size-2x);
  position: relative;
  z-index: 10;
}
.tds-theme--enterprise .navigation--section .navigation-icon-left {
  transform: rotate(-90deg);
}
.tds-theme--enterprise .navigation--section-header {
  padding: 0 var(--tds-nav-item-padding);
}
.tds-theme--enterprise .navigation--section .tds-link-text {
  position: relative;
  bottom: 4px;
}
.tds-theme--enterprise .coin--order-summary {
  margin-block-start: var(--tds-size-4x);
}
.tds-theme--enterprise .coin--order-summary .coin--itemized-list-item--value {
  margin-inline-start: 1ch;
}
.tds-theme--enterprise .coin--product-checkout-card:first-of-type {
  margin-block-start: var(--tds-size-base);
  border-radius: var(--tds-size-base);
}
.tds-theme--enterprise .coin--total--caption {
  display: grid;
}
.tds-theme--enterprise .cf-mini-configurator {
  width: 640px;
}
.tds-theme--enterprise .cf-mini-configurator .cf-select-input {
  border: 1px solid var(--tds-color--grey50);
  background-color: var(--tds-color--white);
}
.tds-theme--enterprise .cf-mini-configurator .tds-checkbox {
  margin-bottom: 0;
}
.tds-theme--enterprise .cf-mini-configurator .cf-checkbox--caption {
  padding-left: var(--tds-checkbox-label-padding-inline-start);
}
.tds-theme--enterprise :is(.tds-o-option-label, .tds-form-caption, .tds-form-feedback) + :is(.custom-select-container) {
  margin-block-start: var(--tds-size-base);
}
.tds-theme--enterprise .enterprise-specs-block .tds-list-item {
  margin: 0;
  display: grid;
  width: auto;
  padding-right: var(--tds-size-base);
}
.tds-theme--enterprise .enterprise-specs-block .specs-item {
  margin-top: var(--tds-size-base);
}
.tds-theme--enterprise .cf-form--group {
  margin-bottom: var(--tds-size-2x);
}
.tds-theme--enterprise .cf-form--group .cf-label--group {
  display: flex;
  align-items: center;
}
.tds-theme--enterprise .cf-form--group .tds-form-item-label {
  margin-right: 0;
}
.tds-theme--enterprise .cf-form--group .cf-form-label {
  white-space: pre-wrap;
  margin-right: var(--tds-size-base);
}
.tds-theme--enterprise .cf-tooltip-content {
  max-width: 400px;
  width: auto;
  max-height: 330px;
  height: auto;
  z-index: 31;
  inline-size: -moz-max-content;
  inline-size: max-content;
}
.tds-theme--enterprise .tds-tooltip-trigger {
  width: var(--tds-size-3x);
  margin-inline-start: 4px;
}
.tds-theme--enterprise .tds-tooltip-img {
  max-height: 100%;
  width: 100%;
  padding-top: var(--tds-size-2x);
}
.tds-theme--enterprise .tds-tooltip-img svg {
  height: 160px !important;
  width: 270px !important;
  margin: var(--tds-size-base) auto 0;
}
.tds-theme--enterprise .hide-side-scroll {
  height: 100%;
}
.tds-theme--enterprise .cf-form-btn--group {
  margin-top: var(--tds-size-3x);
}
.tds-theme--enterprise .coin--itemized-list-item--label {
  white-space: normal;
}
.tds-theme--enterprise .tds-site-nav-item-text {
  text-transform: capitalize;
}
.tds-theme--enterprise .tds-modal-header {
  flex-direction: unset;
}
.tds-theme--enterprise .tds-shell .cf-asset-wrapper {
  padding-inline-start: var(--tds-size-6x);
}

.tds-theme--enterprise .tds-shell .t4b-sidenav-enabled .cf-asset-wrapper {
  padding-inline-start: 0;
}

.tds-theme--enterprise .tds-site-header {
  position: absolute;
  -webkit-backdrop-filter: unset;
          backdrop-filter: unset;
}
/* fix issue in firefox where padding-bottom is ignored for overflow: scroll */
.tds-modal-content:last-child {
  padding-bottom: 0;
}
.tds-modal-content:last-child::after {
  content: "";
  display: block;
  height: var(--tds-size-8x);
}
/* COIN-3461 ~ Make sense out of dynamic grid table for RTL */
[dir=rtl] .swap-rtl .tds-flex {
  direction: ltr;
}
[dir=rtl] .swap-rtl .line-item--value {
  direction: rtl;
  text-align: right;
}
[dir=rtl] .swap-rtl.value-align-left .line-item--value {
  text-align: left;
}
.federal-tax--description .federal-tax--copy-with-link:after {
  content: " ";
}
.federal-tax--description .modal-trigger-container {
  display: block;
}
.federal-tax--description .modal-trigger-container.coin--block-link {
  display: block;
}
.federal-tax--description a, .federal-tax--description button.modal-trigger span {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}
.federal-tax--description a:hover, .federal-tax--description button.modal-trigger span:hover {
  box-shadow: 0 var(--tds-border-width--medium) 0 0 currentColor;
}
.federal-tax--description .modal-trigger-container button.modal-trigger {
  box-shadow: none;
}
.federal-tax--description .modal-trigger-container button.modal-trigger:hover {
  box-shadow: none;
}
.federal-tax--description .federal-tax--link--container {
  display: inline;
}
/* Dark Mode */
.tds-site-header--dark .tds-site-nav-content,
.tds-site-header--dark .tds-site-nav--flyout {
  background-color: var(--tds-color--black) !important;
}
.tds-scrim--black .tds-locale-selector-country--selected {
  background-color: hsla(0, 0%, 100%, 0.2);
}
/* GOOGLE OPTIMIZE - BUTTON EXPERIMENT */
.btn-experiment-02 {
  --tds-btn-background_color: transparent;
  --tds-btn-border_color: var(--tds-color--black);
  color: var(--tds-color--black);
}
.btn-experiment-07 {
  background: linear-gradient(rgb(99, 170, 229), rgb(58, 99, 222));
  background-clip: padding-box;
  border-width: 0;
  padding: 3px;
  color: var(--tds-color--black);
}
.btn-experiment-07 > span {
  display: inline-block;
  flex: 1 1 0;
  line-height: 26px;
  background: white;
  border-radius: calc(var(--tds-btn-radius) - 4px);
}
.btn-experiment-07:active,
.btn-experiment-07:focus,
.btn-experiment-07:hover {
  background: var(--tds-color--blue20);
  color: var(--tds-color--white);
}
.btn-experiment-07:hover > span {
  background: transparent;
}
.btn-experiment-08 {
  --tds-btn-background_color: #f4f4f4;
  color: var(--tds-color--black);
  border-width: 0;
  padding: 3px;
  color: var(--tds-color--black);
}
.btn-experiment-08 > span {
  display: inline-block;
  flex: 1 1 0;
  line-height: 26px;
  background: white;
  border-radius: calc(var(--tds-btn-radius) - 4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}
.btn-experiment-08:hover > span {
  background: transparent;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0);
}
.btn-experiment-08:active,
.btn-experiment-08:focus,
.btn-experiment-08:hover {
  --tds-btn-background_color: #f4f4f4;
  color: var(--tds-color--black);
}
.tds-btn--light {
  --tds-btn-font_size: var(--tds-font-size-20);
}
.incentives-savings--registration-type,
.incentives-savings--toggle-incentive {
  grid-template-rows: auto;
  --tds-form-item--spacer: none;
  padding: 0 var(--tds-size-2x);
}
.incentives-savings--registration-type legend,
.incentives-savings--toggle-incentive legend {
  margin: var(--tds-size-base) 0;
}
.incentives-savings--registration-type .fieldset-choice,
.incentives-savings--toggle-incentive .fieldset-choice {
  flex-flow: inherit;
}
.incentives-driverType--toggle-incentive {
  padding: 0 var(--tds-size-2x);
}
.incentives-driverType--toggle-incentive legend {
  margin: var(--tds-size-base) 0;
}
.driverType__container .tds-fieldset {
  display: flex;
  padding: 0 0 var(--tds-size-base);
}
.driverType__container .registration-type--radios {
  margin-inline-start: var(--tds-size-2x);
  padding: var(--tds-size-half) 0;
}
.tds-o-flex_no-wrap {
  display: flex;
  flex-flow: nowrap;
}
.tds-o-align-self--flex-start {
  align-self: flex-start;
}
.group-block .preorder-disclaimer {
  margin-top: calc(-1 * var(--tds-size-4x));
  margin-bottom: var(--tds-size-2x);
}
.regional-fees {
  width: 100%;
  display: table-row;
}
.regional-fees span:not(.tds-link) {
  display: table-cell;
  padding: 7px 0;
  color: var(--tds-text--color);
  width: inherit;
}
.regional-fees span:not(.tds-link):last-child {
  text-align: end;
  white-space: nowrap;
}
.inventoryCallback label[for=callbackUpdates] .tds-form-label-text {
  color: var(--tds-caption--color);
  font-size: var(--tds-font-size-20);
  line-height: var(--tds-line-height-10);
  font-weight: normal;
}
.action-trigger--btn-link {
  font-family: var(--tds-font-combined);
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}
.tds-list-item.text-loader--list_element:empty {
  display: none;
}
/* Avaya chat override (temp) */
@media (min-width: 600px) {
  .tw-chat--tds-modal--is-open {
    overflow: auto !important;
  }
}
.tds-o-padding_bottom-1x {
  padding-bottom: var(--tds-size-base) !important;
}
.accessories-list-items {
  border-bottom: 1px solid var(--tds-color--grey50);
  margin-bottom: var(--tds-size-2x);
}
.child--group-container {
  flex-flow: wrap;
}
.child--group-container .observer-placeholder {
  min-width: auto;
}
.child--group-container .child-group--section {
  width: 100%;
}
.tds-o-animate--slide_in {
  animation: slide-in 1s var(--bezier);
}
.tds-o-no-margin-bottom {
  margin-bottom: 0;
}
.delivery-selection--container .tds-status_msg > .tds-icon {
  margin-inline-end: 0;
}
.delivery-selection--container .observer-placeholder {
  z-index: 5;
}
.inventoryAvailability {
  display: grid;
  grid-template-columns: minmax(max-content, 7%) 1fr;
  text-align: initial;
  margin: 0 auto;
  -moz-column-gap: 3px;
       column-gap: 3px;
}
.early-delivery--section {
  padding-top: var(--tds-size-2x);
}
.early-delivery--section .tds-form-input--option {
  flex-flow: column;
  align-items: normal;
}
.early-delivery--section .tds-form-input-group > .tds-form-input {
  margin-block-end: var(--tds-size-2x);
}
.early-delivery--section .tds-form-input--option:not(:has(+ .tds-form-input-hidden-choice)) {
  margin-block-end: 0px;
}
.early-delivery--section .tds-form-input-group > p {
  padding-block-start: var(--tds-size-3x);
}
.i18n-inventory-used .coin-inventory-used--hide {
  display: none;
}
.tds-theme--enterprise .coin-enterprise--hide {
  display: none;
}
.coin-text-sup--small {
  top: 20px;
}
/* Custom footnote */
.coin-text--footnote {
  padding-top: var(--tds-size-base);
}
.coin-list--break {
  border-top: 1px solid var(--tds-color--grey60);
  padding-block-start: var(--tds-size-base) !important;
}
tr.coin-table--break {
  border-top: 1px solid var(--tds-color--grey60);
}
tr.coin-table--break td {
  padding-top: var(--tds-size-base) !important;
}
@media (max-width: 1024px) {
  .coin-reloaded .group-container {
    display: flex;
    flex-direction: column;
    gap: var(--tds-size--8x);
  }
  .inventory-group-section .aside-section:first-child:has([data-group-id="FOUNDATION_SERIES"]) + .aside-section {
    margin-top: -64px;
  }
}
/* Mobile devices */
@media (max-width: 599px) {
  .coin-reloaded .tds-site-header {
    background: rgba(255, 255, 255, 1);
  }
  .financial--form-container.selected-tab--incentives .finance-container--line-items .tds-flex {
    flex-flow: row nowrap;
  }
  .financial--form-container.selected-tab--incentives .finance-container--line-items .tds-flex .tds-flex-item {
    inline-size: auto;
  }
  .cf-action-target__mobile {
    width: 100%;
  }
  .cf-action-target__mobile > div {
    align-self: flex-end;
  }
  .tds-flex--justify-center.cf-action-target__mobile > div {
    align-self: center;
  }
  .cf-action-target__mobile > div:only-child {
    align-self: center;
  }
  .cf-action-target__mobile .modal-trigger-container {
    text-align: center;
  }
  .tds--hide_on_desktop {
    display: block;
  }
  .tds--is_invisible_on_desktop {
    display: none;
  }
  .tds-flex--row-nowrap {
    flex-flow: row nowrap;
  }
  .videoContent--container {
    margin-left: var(--tds-size-3x);
  }
  .tds-tabs.tds-tabs--toggle nav .tds-tab[aria-selected=true] {
    color: var(--tds-color--black);
    background-color: var(--tds-color--white);
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .tds-tabs.tds-tabs--toggle nav .tds-tab[aria-selected=true]:focus {
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.1), 0 0 0 0 transparent;
  }
  .tds-shell {
    margin-bottom: -24px;
  }
  html:not(.tds-theme--cyberpunk) body:not(.coin-reloaded) .option-widget:first-child {
    padding-top: var(--tds-size-3x);
  }
  .group-section {
    padding-bottom: 80px;
  }
  .option-widget:first-child .tds--vertical_padding--6x.option-widget--container {
    padding-block-start: 0;
  }
  /* this fix is to override the 100% width on mobile for tds-buttons */
  .tds-btn--medium,
  .tds-btn--small {
    min-width: 142px;
    width: auto;
    white-space: nowrap;
  }
  /* TDS override tds--padding already has an important :( */
  .option-widget--container {
    padding-left: var(--tds-size-3x) !important;
    padding-right: var(--tds-size-3x) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  body:not(.coin-reloaded) .overview-page .option-widget--container {
    margin-bottom: var(--tds-size-5x);
  }
  .option-widget--container .EditDesignLink--section {
    position: absolute;
    top: 0;
    margin: var(--tds-size-base) var(--tds-size-2x);
    left: 0;
  }
  body:not(.coin-reloaded) .aside-section:last-child {
    padding-block-end: calc(25vh - 80px);
    padding-bottom: calc(25vh - 80px);
  }
  .i18n-country-US .earlydelivery-page .aside-section:last-child {
    padding-block-end: 0;
    padding-bottom: 0;
  }
  .emissions-banner {
    text-align: center;
  }
  .coin-reloaded .aside-section:first-child .group-section--container .option-widget--container .text-loader--subtitle {
    padding-block-start: var(--tds-size-base);
    padding-block-end: 0;
  }
  .coin-reloaded .cf-summary_container {
    scroll-margin-top: var(--gallery-fixed-height-mobile);
  }
}
/* Mobile devices end */

/* Smallest mobile device exception */
@media (max-width: 376px) {
  .cf-action-target__mobile.tds-flex--space-between > div {
    margin-left: auto;
    margin-right: auto;
  }
  .cf-action-target__mobile.tds-flex--space-between > div:first-child {
    margin-bottom: var(--tds-size-1x);
  }
  .option-widget--container {
    padding-left: var(--tds-size-2x) !important;
    padding-right: var(--tds-size-2x) !important;
  }
}
/* Smallest mobile device exception end */

.tds-flex--align-center {
  align-self: center;
}
.vehicle-details--label {
  display: inline-block;
}
.vehicle-details--value {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tds-o-text-line_through {
  text-decoration: line-through;
}
.earlydelivery-page .coin-edit-design--section {
  padding: 0 !important;
}
.tds-o-padding_bottom-4 {
  padding-bottom: calc(var(--tds-size-base) / 2);
  padding-block-end: calc(var(--tds-size-base) / 2) !important;
}
.tds-table-row th.tds-o-padding_bottom-16 {
  padding-block-end: var(--tds-size-2x);
}
.main-page--mobile.earlydelivery-page .scroll-container .aside-section:first-child,
.main-page--mobile.earlydelivery-page .scroll-container .aside-section:nth-child(2) {
  position: sticky;
  background-color: var(--tds-color--white);
  z-index: 11;
  padding-bottom: var(--tds-size-base);
}
.main-page--mobile.earlydelivery-page .scroll-container .aside-section:first-child {
  top: 0;
  padding-top: var(--tds-size-base);
}
.main-page--mobile.earlydelivery-page .scroll-container .aside-section:nth-child(2) {
  top: 36px;
}
.main-page--mobile.earlydelivery-page .scroll-container.tds-scrim--black .aside-section:first-child,
.main-page--mobile.earlydelivery-page .scroll-container.tds-scrim--black .aside-section:nth-child(2) {
  background-color: var(--tds-color--black);
}
.tds--vertical_padding--4x {
  padding-block-start: var(--tds-size-4x);
  padding-block-end: var(--tds-size-4x);
  padding-bottom: var(--tds-size-4x);
}
.tds--text-align-left {
  text-align: start;
}
.coin-banner {
  border-radius: calc(var(--tds-size-base) / 2);
}
.tds-o-vertical_padding-top--12x {
  padding-block-start: var(--tds-size-12x);
  padding-top: var(--tds-size-12x);
}
.business_order--container .text-loader--description {
  padding-top: var(--tds-size-2x);
}
.tds-o-margin_left--1x {
  margin-left: var(--tds-size-base);
}
.tds-o-text-align--right {
  text-align: end;
}
.tds-o-text-align_end {
  text-align: end;
}
.tds-o-horizontal-padding-14 {
  padding-left: calc(var(--tds-size-2x) - 2px) !important;
  padding-right: calc(var(--tds-size-2x) - 2px) !important;
}
.address-form--search .tds-form-input-leading {
  display: none;
}
.tds-o-flex-direction--column {
  display: flex;
  flex-direction: column;
}
.tds-o-flex-direction--row {
  display: flex;
  flex-direction: row;
}
.tds-o-flex--inline {
  display: inline-flex;
}
.prequalify-loader--container {
  display: inline-flex;
  height: 200px;
  position: relative;
  align-self: normal;
}
.prequalify-loader--container .tds-loader {
  background: none;
}
.tds--padding_top-8 {
  padding-block-start: var(--tds-size-base) !important;
}
.tds--padding_bottom-8 {
  padding-block-end: var(--tds-size-base) !important;
}
.tds-o-padding_top-4 {
  padding-block-start: calc(var(--tds-size-base) / 2) !important;
}

.tds-o-padding_bottom-2 {
  padding-block-end: calc(var(--tds-size-base) / 4) !important;
}

.tds-o-padding_right-16 {
  padding-right: var(--tds-size-2x);
}

.i18n-country-US .coin--fixed-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--tds-size-2x) var(--tds-size-3x);
  background-color: white;
}

.tds-scrim--dk .i18n-country-US .coin--fixed-footer {
  background-color: var(--tds-color--black);
}

.tds-o-border-bottom-grey60 {
  border-bottom: 1px solid var(--tds-color-grey-60);
}
.tds-o-border-bottom-grey70 {
  border-bottom: 1px solid var(--tds-color-grey-70);
}

.tds-o-border-top-grey60 {
  border-top: 1px solid var(--tds-color-grey-60);
}
.tds-o-border-top-grey70 {
  border-top: 1px solid var(--tds-color-grey-70);
}

.tds-o-padding_right-8 {
  -webkit-padding-end: var(--tds-size-base);
  padding-right: var(--tds-size-base);
}

.tds-o-padding_right-4 {
  -webkit-padding-end: calc(var(--tds-size-base) / 2);
  padding-right: calc(var(--tds-size-base) / 2);
}
.coin-reloaded.coin-navup .cf-asset-wrapper--fixed {
  top: 0;
}
.cf-content_container {
  scroll-margin-top: var(--tds-site-header--height);
}
.coin-reloaded .incentives-toggle--section + .tds-form-input-choice-label {
  padding-block-start: 0;
}

.coin-reloaded .incentives-toggle--section + .tds-form-input-choice-label .tds-form-label {
  color: var(--tds-base-text-color);
  font-weight: 400;
}

.tds-flex-gap--4 {
  gap: var(--tds-size-half);
}

.tds-flex-gap--8 {
  gap: var(--tds-size-1x);
}

.tds-flex-gap--16 {
  gap: var(--tds-size-2x);
}

.tds-o-no-padding-top {
  padding-top: 0 !important;
}

.coin-reloaded .cf-h2-title {
  padding-top: 0;
  padding-block-start: 0;
  padding-bottom: 0;
}

.text-loader--list_element:empty,
.coin-reloaded .aside-section:has(.group-section--container:empty) {
  display: none;
}

.coin-reloaded .cf-summary_container--loading {
  height: 600px;
}

body:not(.coin-reloaded) .group--child-container .generic-group-description {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.tds-flex-gap--9x {
  gap: var(--tds-size--9x)
}

.tds-o-transparent-bg {
  background-color: transparent;
}

.tds-o-margin-auto {
  margin: auto;
}

input::-webkit-date-and-time-value {
  text-align: start;
}

.coin-skeleton--loader {
  width: 80px;
  opacity: 30%;
  border-radius: 2px;
  color: transparent;
  background: linear-gradient(to right, rgba(57, 60, 65, 1), rgba(57, 60, 65, 0.3), rgba(57, 60, 65, 0.1));
}

.cf-summary-list .text-loader--list_items:has(.text-loader--list_element:empty),
.tds-content_container .pricing-container .price-block .tds-list-item .tds-list--unordered .tds-list-item:empty,
.cf-summary-list .text-loader:has(.coin-list--sub-item) + span,
.cf-summary-list .coin-list--sub-item + span {
  display: none;
}
.cf-summary-list .coin-list--sub-item {
  padding-left: var(--tds-size-3x);
}

.coin-reloaded .cf-summary-list .coin-list--sub-item + span {
  display: block;
}
.coin-reloaded .cf-summary-list .coin-list--sub-item {
  padding-left: 0;
}

.tds-o-flex-start {
  align-items: flex-start;
}

.tds-o-width-auto {
  width: auto !important;
}

.tds-o-text-align--left {
  text-align: left;
}

body:not(.coin-reloaded) .coin--is_hidden {
  display: none;
}

.tds-scrim--black .tds-loader {
  background-color: inherit;
}

.tds-o-min-width-auto {
  min-width: auto !important;
}

.text-loader--with-icon {
  display: flex;
}
.text-loader--with-icon .text-loader--icon {
  margin-right: 5px;
}
[dir=rtl] .text-loader--with-icon {
  flex-direction: row-reverse;
}
[dir=rtl] .text-loader--with-icon .text-loader--icon {
  margin-right: 0;
  margin-left: 5px;
}

.tds-o-horizontal--scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-visible {
  overflow-x: visible !important;
}

.tds-o-layout--grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 var(--tds-size--3x);
  margin: auto;
}

button.tds-o-button--unstyled {
  line-height: normal;
  display: inline;
  --tds-link--icon-margin: calc((var(--tds-icon--size) + var(--tds-size--half))* -1);
  --tds-link--box-shadow--default: 0 calc(1px + var(--tds-border-width--small)) 0 -1px currentcolor;
  --tds-link--box-shadow--hover: 0 calc(1px + var(--tds-border-width--medium)) 0 -1px currentcolor;
  --tds-link--box-shadow--none: 0 0 0 0 transparent;
  background-color: transparent;
  border: 0;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: none;
  color: var(--tds-theme-foreground);
  cursor: pointer;
  padding: 0;
  position: relative;
  text-align: initial;
}

.coin-reloaded .pricing-container .cf-summary-list li.tds-list-item > :first-child {
  inline-size: 70%;
}
.coin-reloaded .pricing-container .cf-summary-list li.tds-list-item > .price-indicator {
  inline-size: calc(30% - var(--tds-size-1x));
  margin-inline-start: var(--tds-size-1x);
}

/* Pre order swap styles */
.tds-o-list--expanded {
  --tds-list-item--margin-block: var(--tds-size--2x);
  --tds-list-item--padding-inline: var(--tds-size--1x);
  padding-inline-start: var(--tds-size--3x);
}
/* Coin Vehicle Card start */
.coin-vehicle-card {
  border: 1px solid #E2E3E3;
  border-radius: 8px;
  padding: 15px;
  margin: var(--tds-size-1x) 0;
}
.coin-vehicle-card--badge-img {
  max-height: var(--tds-size-3x);
}
.coin-vehicle-card--details {
  grid-template-columns: 65% calc(35% - var(--tds-size--1x)) !important;
  grid-gap: 0 var(--tds-size--1x);
}
.coin-vehicle-card--content .tds-icon {
  color: #5C5E62;
}
.coin-preorderswap-entry-container .tds-card-body {
  padding: calc(var(--tds-size--1x) + 4px) var(--tds-size--2x);
}
.inventory-card-chip {
  position: absolute;
  top: var(--tds-size-1x);
  left: var(--tds-size-1x);
  z-index: 1;
}
.inventory-card-chip .tds-chip-text {
  font-size: var(--tds-font-size-20);
  line-height: var(--tds-line-height-10);
}
@media (max-width: 599px) {
  .coin-vehicle-card {
    padding: 15px !important;
  }
  .tds-o-table .tds-table-row td,
  .tds-o-table .tds-table-row th {
    min-inline-size: fit-content;
  }
  .tds-o-table .tds-table-row th:not(:last-child,:first-child) {
    padding: 0 var(--tds-size-1x);
  }
}
/* Coin Vehicle Card end */
.tds-o-nogap {
  gap: 0;
}
.tds-o-steering-wheel,
.coin-vehicle-card--content .tds-icon.tds-o-steering-wheel {
  color: var(--tds-color-blue-30);
}

.post-order-swap--alerts .tds-status_msg {
  margin-top: var(--tds-size--2x);
  margin-bottom: 0;
}

.post-order-swap--alerts .tds-card-body {
  text-align: left;
}

.preorder-gallery {
  margin-left: -20px;
  margin-right: -20px;
}

.referral-container {
  display: flex;
  max-width: 290px;
}

.coin-status-mgs--delivery-disclaimer {
  margin-block-start: var(--tds-size-1x);
  margin-block-end: 0;
}
.coin-reloaded--my-badge {
  max-height: 26px;
}

.t4b-sidenav-wrapper {
  position: relative;
  left: var(--tds-size-3x);
}

#t4b-sidenav {
  position: sticky;
  top: var(--tds-shell-header-height);
  z-index: 20;
}

.coin--disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: var(--tds-ui-opacity-70);
}

.coin--disabled .tds-form-input-hidden-choice[disabled] + .tds-form-input {
  opacity: 1;
}