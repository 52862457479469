.tds--indicate-focus {
  --tds-transition--box-shadow: box-shadow 0s;
}
.tds--indicate-focus .modal-trigger:focus-within {
  outline: 1px dotted currentColor !important;
}
.tds--indicate-focus .modal-trigger:focus-within .footer-modal-trigger,
.tds--indicate-focus .modal-trigger:focus-within p.tds-btn--light {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.2);
}
.tds--indicate-focus .modal-trigger:focus-within div.tds-btn--blue {
  box-shadow: inset 0 0 0 2px var(--tds-color--white);
}
.tds--indicate-focus button.text-loader--content.tds-btn--light:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.2);
}
.tds--indicate-focus button.text-loader--content.tds-btn--blue:focus {
  box-shadow: inset 0 0 0 2px var(--tds-color--white);
}
.tds--indicate-focus .group--detail-container input[type=radio]:focus + label svg {
  box-shadow: inset 0px 0px 0px 6px var(--tds-color--blue30);
}
.tds--indicate-focus .gallery_control:focus .tds-icon {
  box-shadow: inset 0 0 0 3px rgba(62, 106, 255, 0.5);
}
.tds--indicate-focus .group--options_block--container input[type=radio]:focus + label {
  box-shadow: inset 0 0 0 2px var(--tds-color--blue30);
  border: 2px solid var(--tds-color--blue30);
}
.tds--indicate-focus .group--options_circle--container input[type=radio]:focus + label .group-option_circle-content::after {
  box-shadow: 0 0 0 0px var(--tds-color--white), 0 0 0 6px var(--tds-color--blue30);
  margin: -4px;
}
