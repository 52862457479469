.test-drive-cta-btn {
  display: none;
  visibility: hidden;
  position: fixed;
  right: calc(var(--tds-size-4x) + var(--tds-size-half));
  /* Match footer Order Now right alignment */
  bottom: var(--tds-size-11x);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 500;
  block-size: var(--tds-size-5x);
  padding: var(--tds-size-2x);
  border: none;
  border-radius: var(--tds-border-radius);
  background: var(--tds-color-grey-20);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  z-index: 998;
  transition: transform 0.5s var(--tds-bezier) 0s;
}

@media (max-width: 1024px) {
  .test-drive-cta-btn__down {
    transition: transform 0.5s var(--tds-bezier) 0s;
    transform: translate3d(0, 70px, 0);
  }
}

@media (min-width: 1025px) {
  .test-drive-cta-btn {
    display: flex;
    visibility: visible;
    left: var(--tds-size-12x);
    bottom: var(--tds-size-3x);
    right: auto;
  }
}

@media (min-width: 1200px) {
  body:has(.tw-chat--avaya-chat) .test-drive-cta-btn {
    display: flex;
    visibility: visible;
    block-size: var(--tds-size-6x);
    padding: var(--tds-size-3x);
    left: var(--tds-size-13x);
    bottom: var(--tds-size-4x);
  }
}

[dir="rtl"] .test-drive-cta-btn {
  right: auto;
  left: calc(var(--tds-size-4x) + var(--tds-size-half));
}

@media (min-width: 1025px) {  
  [dir="rtl"] .test-drive-cta-btn {
    left: auto;
    right: var(--tds-size-12x);
  }
}

@media (min-width: 1200px) {  
  [dir="rtl"] body:has(.tw-chat--avaya-chat) .test-drive-cta-btn {
    left: auto;
    right: var(--tds-size-13x);
  }
}
