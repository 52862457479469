.feature-highlight {
  display: flex;
  height: 100%;
  border-radius: var(--tds-size-2x);
  overflow: hidden;
}

.feature-asset-container .asset-loader--svg-container {
  display: block;
  margin: 0;
}

.feature-copy-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  /* TextLoader Overrides */
}

@media (min-width: 1025px) and (orientation: landscape) {
  .tds-o-mega-modal .feature-highlight {
    display: grid;
    grid-template: "main aside" 1fr / 9fr minmax(342px, 1fr);
    padding-inline-end: var(--tds-size-5x);
    height: 100%;
  }
  
  .tds-o-mega-modal .feature-asset-container .asset-loader--svg-container {
    grid-area: main;
    margin: 0;
  }
  
  .tds-o-mega-modal .feature-copy-container {
    grid-area: aside;
  }
}

.feature-copy-container .feature-panel-name {
  margin-top: 24px;
}

.feature-copy-container .feature-description:not(p) {
  padding-bottom: var(--tds-size-7x);
  margin-top: 0;
  margin-bottom: var(--tds-size-2x);
  scrollbar-width: none;
}

.feature-copy-container .feature-eyebrow {
  padding-top: 0;
}

.feature-copy-container .text-loader--content.tds-text--h3, .feature-copy-container .text-loader--content.tds-text--h6 {
  font-family: var(--tds-font-combined--medium);
  color: var(--tds-color--grey10);
  padding-block-end: var(--tds-size-half);
  padding-block-start: var(--tds-size-3x);
}

.feature-copy-container .text-loader--content.tds-text--h3 {
  letter-spacing: -0.6px;
  font-size: var(--tds-font-size-60, 24px);
  line-height: var(--tds-line-height-60, 28px);
}

.feature-copy-container .text-loader--content.tds-text--h4 {
  letter-spacing: -0.4px;
  line-height: var(--tds-line-height-60, 28px);
}

.tds-btn.tds-o-remove-margins {
  margin: 0;
}

.tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  order: 99;
  padding-top: var(--tds-size-2x);
  background: linear-gradient(to top, var(--tds-color--white) 70%, hsla(0, 0%, 100%, 0));
}

.tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper .feature-actions {
  margin-top: 0;
  padding-bottom: 0;
}

.tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper .feature-copy-gradient {
  position: relative;
}

.tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper .feature-actions-button-wrapper {
  width: 100%;
  padding-bottom: var(--tds-size-3x);
}

.carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container:after {
  box-shadow: 0px -10px 10px -5px var(--tds-o-gradient-bg-color);
  height: var(--tds-size-3x);
  content: "";
  display: flex;
  flex: none;
  order: 99;
  z-index: 1;
}

.carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper {
  padding: 0;
}

@media (min-width: 599px) and (orientation: portrait) {
  .tds-modal--carousel-panels .feature-copy-container {
    margin-bottom: var(--tds-size-8x);
  }
}

@media (orientation: landscape) {
  .tds-modal--carousel-panels .feature-copy-container {
    /* fix issue in firefox where padding-bottom is ignored for overflow: scroll */
    padding-bottom: 0;
  }
  .tds-modal--carousel-panels .feature-copy-container:after {
    content: "";
    display: flex;
    flex: none;
    height: var(--tds-size-13x);
    order: 99;
    z-index: 1;
  }
  .tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper {
    position: absolute;
    left: auto;
    width: calc(100% - var(--carousel-panel-height));
    padding-left: var(--tds-size-3x);
    padding-right: var(--tds-size-3x);
  }
  [dir=rtl] .tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper {
    left: 0;
    right: auto;
  }
}

.info-message-with-referral {
  padding-top: var(--tds-size-base);
  padding-bottom: var(--tds-size-3x);
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title {
  order: 1;
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description {
  order: 2;
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-panelName, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-panelName {
  order: 3;
  flex-grow: 1;
  align-items: flex-end;
  text-align: center;
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-eyebrow, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-eyebrow {
  order: 4;
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-actions, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-actions {
  order: 5;
  flex-grow: 0;
  margin-top: var(--tds-size-3x);
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description {
  margin-top: var(--tds-size-base);
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description {
  margin-top: var(--tds-size-base);
}

[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-panelName,
[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-eyebrow, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-panelName,
[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-eyebrow {
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding: 0;
  font-family: var(--tds-font-combined--medium);
}

.carousel-modal.tds-o-mega-modal[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description,
.carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-description {
  overflow-y: visible;
  margin-top: 0;
  margin-bottom: 0;
  overscroll-behavior: contain;
}
.carousel-modal.tds-o-mega-modal[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title,
.carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title {
  box-shadow: 0px 10px 10px -5px var(--tds-o-gradient-bg-color);
  z-index: 1;
}
.tds-theme--dk .carousel-modal.tds-o-mega-modal[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title,
.tds-theme--dk .carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title {
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 1);
}
.carousel-modal.tds-o-mega-modal[id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title span,
.carousel-modal.tds-o-mega-modal[id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .feature-title span {
  padding-bottom: var(--tds-size-base);
}

[data-group-id="InventoryFeatureHighlights"] .option-widget--container + .option-widget--container {
  padding-block-start: 10vh;
}

/* Override for FeatureHighlight asset styles, based on panel width */

@media (orientation: portrait) {
  .feature-highlight {
    flex-direction: column;
  }
  .tds-tab-panel--active .feature-copy-gradient {
    width: 100% !important;
  }
  .feature-asset-container .asset-loader--svg-container,
  .feature-asset-container .asset-loader-2-video-inline,
  .feature-asset-container [id^=video-id] {
    height: var(--carousel-panel-width);
    width: var(--carousel-panel-width);
  }
}

@media (orientation: landscape) {
  .feature-copy-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .feature-asset-container .asset-loader--svg-container,
  .feature-asset-container .asset-loader-2-video-inline,
  .feature-asset-container [id^=video-id] {
    height: var(--carousel-panel-height);
    width: var(--carousel-panel-height);
  }

  .tds-o-mega-modal .feature-asset-container .asset-loader-2-video-inline,
  .tds-o-mega-modal .feature-asset-container [id^=video-id] {
    width: 100%;
    height: 100%;
  }
}

.asset-loader-2-video-title {
  position: absolute;
  text-align: center;
  width: 100%;
  color: var(--tds-color--white);
}

/* Style for Mobile and Tablet Portrait view specific to FSD*/
@media (orientation: portrait) and (max-width: 1024px) {
  .tds-o-mega-modal .carousel-modal--panel{
    border-radius: var(--tds-size-2x) var(--tds-size-2x) 0 0;
  }
  .tds-o-mega-modal .feature-copy-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tds-o-mega-modal .tds-tab-panel--active .feature-copy-gradient {
    width: 100% !important;
  }
  .tds-o-mega-modal .feature-asset-container .asset-loader--svg-container,
  .tds-o-mega-modal .feature-asset-container .asset-loader-2-video-inline,
  .tds-o-mega-modal .feature-asset-container [id^=video-id] {
    height: var(--carousel-panel-width);
    width: var(--carousel-panel-width);
  }
}

/* Style for Portrait view over 1025 px in FSD(Using Landscape styles)*/
@media (orientation:portrait) and (min-width: 1025px) {
  .tds-o-mega-modal .feature-highlight {
    flex-direction: unset;
  }
  .tds-o-mega-modal .feature-asset-container .asset-loader--svg-container,
  .tds-o-mega-modal .feature-asset-container .asset-loader-2-video-inline,
  .tds-o-mega-modal .feature-asset-container [id^=video-id] {
    height: var(--carousel-panel-height);
    width: var(--carousel-panel-height);
  }
  .tds-o-mega-modal .feature-asset-container .asset-loader-2-video-inline,
  .tds-o-mega-modal .feature-asset-container [id^=video-id] {
    width: 100%;
    height: 100%;
  }
}

.asset-loader-2-video-inline figcaption.video-caption {
  position: absolute;
  bottom: var(--tds-size-2x);
  left: 0;
  width: 100%;
  text-align: center;
  color: var(--tds-color--white);
  padding-bottom: 0;
}

#featureGroup-EAP_SUPERVISED figcaption.video-caption {
  color: var(--tds-color-grey-20);
}

@media (min-width: 1025px) {
  .asset-loader-2-video-inline figcaption.video-caption {
    bottom: var(--tds-size-6x);
  }
}

.feature-copy-gradient {
  position: absolute;
  bottom: 0;
  height: var(--tds-size-4x);
  right: 0;
  display: block;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
  width: calc(var(--carousel-panel-width) - var(--carousel-panel-height));
}

.tds-scrim--black .tds-tab-panel--active .feature-copy-gradient {
  background: none;
}

@media (max-width: 1025px) {
  .tds-tab-panel--active .feature-highlight-gradient {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
    height: var(--tds-size-3x);
    width: 100%;
    z-index: 2010;
    display: block;
  }
}

@media (max-width: 599px) {
  [id=EAP_FEATURES] .tds-modal--carousel-panels .feature-description, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-description {
    padding-bottom: var(--tds-size-3x);
  }
  [id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container {
    /* min-height: -moz-fit-content;
    min-height: fit-content; */
    padding: 0 var(--tds-size-3x);
  }
  [id=EAP_FEATURES] .tds-modal--carousel-panels .feature-copy-container .text-loader--content.tds-text--h3, [id=FSD_FEATURES] .tds-modal--carousel-panels .feature-copy-container .text-loader--content.tds-text--h3 {
    margin-top: 0;
    padding-top: var(--tds-size-2x);
  }
  .tds-modal--carousel-panels .feature-copy-container .feature-actions-wrapper .feature-copy-gradient {
    position: absolute;
  }
  [data-group-id="InventoryFeatureHighlights"] .option-widget--container + .option-widget--container {
    margin-block-start: 112px;
  }
}

.compare-features--container .compare-features--asset .asset-loader--svg-container {
  width: 100%;
  border-radius: var(--tds-size-1x);
  height: 100%;
}


.compare-features--item {
  display: grid;
  grid-gap: 0 var(--tds-size--3x);
  grid-template: 1fr / repeat(2, 6fr);
  margin: auto;
}
.compare-features--item--three {
  grid-template: 1fr / repeat(3, 6fr);
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.compare-features--item--one {
  grid-template: 1fr / repeat(1, 6fr);
}
.compare-features--item--one .tds-layout-item .compare-features--asset {
  justify-content: center;
}
.learn-more-feature-link {
  padding-block: 0 !important;
  width: auto !important;
}

.feature-svg--override,
.compare-features--container .compare-features--asset .asset-loader--svg-container.feature-svg--override,
.compare-features--asset:has(.asset-loader--unavailable) {
  width: var(--tds-size-6x);
  height: var(--tds-size-6x);
}
.compare-features--container .compare-features--section-sticky {
  position: sticky;
  top: var(--tds-size-3x);
  z-index: 3;
  background: var(--tds-theme-background);
}
.compare-features--container .compare-features--section-sticky .compare-features--item .tds-layout-item:last-child .text-loader--content {
  padding-right: var(--tds-size-3x);
}

.drive-anywhere--asset-container {
  width: var(--tds-size-10x);
  height: var(--tds-size-10x);
}

.text-loader--asset-container:has(.drive-anywhere--asset-container) {
  padding-top: var(--tds-size-4x);
  gap: var(--tds-size-2x);
  display: flex;
  flex-direction: row;
}

.text-loader--asset-container:has(.fsd-video--btn-container) {
  gap: var(--tds-size-2x);
  display: flex;
  flex-direction: row;
  border-radius: var(--coin-border-radius-asset-btn);
  border: 1px solid var(--tds-form-input--border-color, var(--tds-theme-border));
  background: var(--tds-theme-background);
  height: 89px;
}

.fsd-video--btn-container {
  width: 115px;
  height: 87px;
  border-top-left-radius: var(--coin-border-radius-asset-btn-img);
  border-bottom-left-radius: var(--coin-border-radius-asset-btn-img);
}

.features-copy--section--container + .features-copy--title {
  padding-block-start: var(--tds-size-5x) !important;
}

.features-copy--small--container + .features-copy--disclaimer {
  padding-block-start: calc(var(--tds-size--6x) + var(--tds-size--3x)) !important;
}

.features-copy--section--container:has(+ .features-copy--title) {
  padding-block-end: var(--tds-size-5x);
  border-bottom: 1px solid var(--tds-color--grey50);
} 

@media (max-width: 599px) {
  .compare-models--container:has(+ .features-copy--title) {
    margin-block-end: var(--tds-size-3x);
    padding-block-end: var(--tds-size-3x);
  }
  .compare-models--container + .features-copy--title {
    padding-block-start: var(--tds-size-3x) !important;
  }
  .features-copy--section--container h3.text-loader--subtitle {
    font-size: var(--tds-font-size-40);
    line-height: var(--tds-line-height-30);
    padding-block-end: var(--tds-size-1x);
    padding-block-start: var(--tds-size--4x);
  }
  .features-copy--section--container .tds-list {
    font-size: var(--tds-font-size-30);
    line-height: var(--tds-line-height-30);
    --tds-list-item--margin-block: var(--tds-size--2x);
  }
  .features-copy--section.features-copy--small--container h3.features-copy--section.features-copy--small {
    font-size: var(--tds-font-size-30);
    line-height: var(--tds-line-height-30);
    padding-block-end: var(--tds-size-1x);
    padding-block-start: var(--tds-size--5x);
  }
  .tds-text--caption-header {
    font-size: var(--tds-font-size-20);
    line-height: var(--tds-line-height-10);
  }
  .compare-features--item .tds-layout-item .tds-text--h4 sup,
  .compare-features--section .tds-text--h4 sup {
    font-size: var(--tds-font-size-10);
    line-height: var(--tds-line-height-10);
    top: -0.8em;
  }
  .compare-features--item {
    grid-gap: 0 var(--tds-size--1x);
  }
  .compare-features--item--one .tds-layout-item .compare-features--asset svg {
    max-width: 266px;
  }
  .compare-features--item--three .tds-layout-item .text-loader--content {
    hyphens: auto;
  }
}
@media (min-width: 600px) {
  .compare-models--container:has(+ .features-copy--title) {
    margin-block-end: var(--tds-size-4x);
  }
  .features-copy--section--container h3.text-loader--subtitle {
    font-size: var(--tds-font-size-50);
    line-height: var(--tds-line-height-60);
    padding-block-end: var(--tds-size-1x);
    padding-block-start: var(--tds-size--4x);
  }
  .features-copy--section--container .tds-list {
    font-size: var(--tds-font-size-40);
    line-height: var(--tds-line-height-20);
    --tds-list-item--margin-block: var(--tds-size--2x);
  }
  .features-copy--section.features-copy--small--container h3.features-copy--section.features-copy--small {
    font-size: var(--tds-font-size-40);
    line-height: var(--tds-line-height-20);
    padding-block-end: var(--tds-size-1x);
    padding-block-start: var(--tds-size--5x);
  }
  .tds-text--caption-header {
    font-size: var(--tds-font-size-20);
    line-height: var(--tds-line-height-10);
  }
  .compare-features--item .tds-layout-item .tds-text--h4 sup,
  .compare-features--section .tds-text--h4 sup {
    font-size: var(--tds-font-size-20);
    line-height: var(--tds-line-height-10);
    top: -0.8em;
  }
  .compare-features--item--one .tds-layout-item .compare-features--asset svg {
    max-width: 434px;
  }
}