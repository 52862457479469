.company-search {
  position: relative;
}
.company-search input {
  --tds-form-item-horizontal_spacer: 40px;
}
.company-search--suffix {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 12px;
}
.company-search--suffix button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}
.company-search--suffix {
  left: auto;
  right: 0;
}
.company-search-details h6 {
  padding-block-start: 0;
  padding-block-end: 5px;
}
.company-search-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-height: calc(8 * var(--tds-height--pill));
  overflow: auto;
  background: var(--tds-color--white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: none;
  margin: calc(var(--tds-height--pill) + var(--tds-size-3x)) 0 0 0;
  padding: 0;
  border-radius: var(--tds-border-radius--card);
  z-index: 99;
}
.company-search-list li {
  cursor: pointer;
}
.company-search-list li:hover {
  background: var(--tds-color--grey70);
}
.company-search-list.is-open {
  display: block;
}
@keyframes tds--rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tds--pulsing {
  animation: var(--tds--pulsing);
}
:root {
  --tds-new-icon--bg: transparent;
  --tds-new-icon--size: 24px;
  --tds--rotating: tds--rotating 1.5s infinite linear;
}
.tds-new-icon {
  background: var(--tds-new-icon--bg);
  block-size: var(--tds-new-icon--size);
  content: "";
  display: block;
  inline-size: var(--tds-new-icon--size);
  max-inline-size: 100%;
  min-block-size: var(--tds-new-icon--size);
  min-inline-size: var(--tds-new-icon--size);
  overflow: hidden;
  text-indent: -9999px;
}
.tds-new-icon-loader {
  animation: var(--tds--rotating);
  opacity: var(--tds-opacity--70);
}
.tds-new-icon-cross {
  --tds-new-icon--size: 16px;
  margin: var(--tds-size-half);
}
