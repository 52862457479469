.load-latest-lexicon-modal .content {
  font-size: var(--tds-font-size-40);
  font-weight: var(--tds-font-weight-bold);
  line-height: var(--tds-line-height-40);
  text-align: left;
  padding-top: var(--tds-size-3x);
  padding-bottom: var(--tds-size-3x);
}
.load-latest-lexicon-modal .sub-content {
  padding-bottom: var(--tds-size-3x);
}
.load-latest-lexicon-modal .button-cancel {
  border: 3px solid var(--tds-color--black);
}
