.modal-trigger.tds-o-btn-link {
  font-family: var(--tds-font-family-base, system);
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}

@media (max-width: 599px) {
  .modal-trigger.tds-o-btn-link {
    width: auto;
  }
}

.modal-trigger.tds-o-btn--blue {
  color: var(--tds-color--blue30) !important;
}

.modal-trigger.tds-o-text-align {
  text-align: initial;
}

.tds-status_msg-text .modal-trigger.tds-o-btn-link {
  font-size: var(--tds-base-font_size);
}

.modal-trigger-container.save-design {
  margin: var(--tds-size-2x) 0 var(--tds-size-2x) 0;
}

.modal-trigger-container.gas-saving {
  margin: var(--tds-size-base) 0;
}

.coin-reloaded .modal-trigger-container.gas-saving {
  margin: 0 0 var(--tds-size-base) 0;
}

/* Add custom class for inventory redesign and keep link style on old order page
   Can be added to TTP when we remove old order page */

.inspection-modal-trigger a, .inspection-modal-trigger a:hover {
  color: var(--tds-color--blue30);
  font-family: var(--tds-font-combined--medium);
  box-shadow: none;
  text-decoration: none;
  display: inline;
}

/* TODO: remove custom style and use link style button after upgrade to tds.4.x */

.modal-trigger-container button.modal-trigger.delivery-location-modal-trigger {
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
  transition: box-shadow 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75), color 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  font-family: var(--tds-font-family-base, system);
  width: auto;
}

.modal-trigger-container button.modal-trigger.delivery-location-modal-trigger:hover {
  transition: box-shadow 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75), color 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  box-shadow: 0 var(--tds-border-width--medium) 0 0 currentColor;
}

.tds-status_msg-body .modal-trigger-container button.modal-trigger {
  color: var(--tds-color--grey20);
}

.tds-status_msg-body .modal-trigger-container button.modal-trigger:hover {
  color: var(--tds-color--black);
}
