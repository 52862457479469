.tds-modal.modal-container--feature_cards_gallery_modal {
    --feature-cards-modal-size: 100vw;
    scrollbar-width: none;
    inline-size: var(--feature-cards-modal-size);
    bottom: 0;
    /* This overrides another !important :( */
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.tds-modal.modal-container--feature_cards_gallery_modal .heading {
    position: sticky;
    top: 0;
    background: var(--tds-dialog--background-color);
    z-index: 3;
    margin: 0 calc(var(--tds-size--3x) * -1);
    padding: 0 var(--tds-size--3x);
    border-radius: var(--tds-modal-border-radius) var(--tds-modal-border-radius) 0 0;
}


.tds-modal.modal-container--feature_cards_gallery_modal .tds-tab-list {
    margin-block-start: var(--tds-size-3x);
    margin-block-end: var(--tds-size-4x);
}

.tds-modal.modal-container--feature_cards_gallery_modal .tds-tab-list .tds--animated-backdrop {
    min-inline-size: 50%;
}


.modal-container--feature_cards_gallery_modal .tds-modal-header {
    padding: 0;
}

.modal-container--feature_cards_gallery_modal .tds-modal-header> :is(h1, h2, h3) {
    padding-block-start: var(--tds-size-2x);
}

.modal-container--feature_cards_gallery_modal .tds-modal-content {
    padding-top: var(--tds-size--3x);
}

.modal-container--feature_cards_gallery_modal .tds-modal-content .tds-text--h1 {
    font-size: var(--tds-size--5x);
}

.feature-cards-gallery-modal .tds-card .tds-card-body p,
.tds-modal.modal-container--feature_cards_gallery_modal .description {
    color: var(--tds-theme-foreground-low-contrast);
    max-inline-size: fit-content;
}

.feature-cards-gallery-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.feature-cards-gallery-modal>*:not(:first-child) {
    margin-top: var(--tds-size-5x);
}

.feature-cards-gallery-modal .tds-card {
    flex: 0 0 auto;
    border-radius: 0;
    background: none;
    overflow: visible;
    max-inline-size: unset;
}

.feature-cards-gallery-modal .tds-card .tds-card-asset,
.feature-cards-gallery-modal .tds-card img {
    border-radius: calc(var(--tds-border-radius--card) * 2);
    overflow: hidden;
}

.feature-cards-gallery-modal .tds-card .tds-card-body {
    padding: var(--tds-card--padding) 0;
}

/**
 * Full width card element
 * Makes the card full width ignoring modal gaps and removes boder radius on asset
 */
.feature-cards-gallery-modal .tds-card.full-width {
    margin: 0 calc(var(--tds-size--3x) * -1);
}

.feature-cards-gallery-modal .tds-card.full-width .tds-card-asset {
    width: 100%;
    aspect-ratio: 384 / 193;
}

.feature-cards-gallery-modal .tds-card.full-width .tds-card-asset,
.feature-cards-gallery-modal .tds-card.full-width img {
    border-radius: 0;
}

.feature-cards-gallery-modal .tds-card.full-width .tds-card-body {
    padding: var(--tds-card--padding) var(--tds-size--3x);
}

.feature-cards-gallery-modal .tds-card-body:empty {
    display: none;
}

/**
 * Horizontal scrollable gallery
 */

.feature-cards-gallery {
    display: flex;
    margin: 0 calc(var(--tds-size--3x) * -1);
    padding: 0 var(--tds-size--3x);
    overflow-x: auto;
    flex-shrink: 0;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}

.feature-cards-gallery>*:not(:first-child) {
    margin-left: var(--tds-size--2x);
}

[dir="rtl"] .feature-cards-gallery>*:not(:first-child) {
    margin-left: 0;
    margin-right: var(--tds-size--2x);
}

.feature-cards-gallery::-webkit-scrollbar {
    display: none;
}

.feature-cards-gallery .tds-card {
    flex: 0 0 80%;
    scroll-snap-align: center;
    position: relative;
}

.feature-cards-gallery.full_width .tds-card {
    flex: 0 0 100%;
}

.feature-cards-gallery .tds-card .tds-card-asset {
    width: 100%;
    position: relative;
    aspect-ratio: 23 / 20;
}

.feature-cards-gallery .tds-card .tds-card-asset img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    z-index: 1;
    contain: strict;
}

.feature-cards-gallery .tds-card .tds-card-asset .asset-loader-label {
    display: block;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%);
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--tds-padding--card--dense);
    color: var(--tds-color-white);
    font-weight: var(--tds-font-weight-medium);
    z-index: 2;
}

.feature-cards-gallery-buttons {
    position: relative;
    --negative-arrows-margin: calc(var(--tds-size-1x) * -1);
}

.feature-cards-gallery-buttons>button {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.feature-cards-gallery-buttons>button.hidden {
    opacity: 0;
}

.feature-cards-gallery-buttons>button .tds-icon-btn {
    background: var(--tds-dialog--background-color);
    block-size: inherit;
}

.feature-cards-gallery-buttons .prev {
    left: var(--negative-arrows-margin);
}

.feature-cards-gallery-buttons .next {
    right: var(--negative-arrows-margin);
}

[dir="rtl"] .feature-cards-gallery-buttons .prev {
    left: unset;
    right: var(--negative-arrows-margin);
}

[dir="rtl"] .feature-cards-gallery-buttons .next {
    right: unset;
    left: var(--negative-arrows-margin);
}

@media (min-width: 600px) {
    .tds-modal.modal-container--feature_cards_gallery_modal {
        --feature-cards-modal-size: 1000px;
        max-inline-size: calc(100vw - var(--tds-size--6x));
        transform: translateX(0) translateY(0);
    }

    .tds-modal.modal-container--feature_cards_gallery_modal .heading {
        margin: 0 calc(var(--tds-size--6x) * -1);
        padding: 0 var(--tds-size--6x);
    }

    .modal-container--feature_cards_gallery_modal .tds-modal-content .tds-text--h1 {
        font-size: var(--tds-size--6x);
    }

    .modal-container--feature_cards_gallery_modal .tds-tab {
        font-size: var(--tds-font-size-50);
    }

    .feature-cards-gallery-modal>*:not(:first-child) {
        margin-top: var(--tds-size-6x);
    }

    .feature-cards-gallery-modal .tds-card.full-width {
        margin: 0 calc(var(--tds-size--6x) * -1);
    }

    .feature-cards-gallery-modal .tds-card.full-width .tds-card-asset,
    .feature-cards-gallery-modal .tds-card.full-width img {
        width: 100%;
        border-radius: 0;
    }

    .feature-cards-gallery-modal .tds-card.full-width .tds-card-body {
        padding: var(--tds-card--padding) var(--tds-size--6x);
    }

    .feature-cards-gallery {
        margin: 0 calc(var(--tds-size--6x)*-1);
        padding: 0 calc(var(--tds-size--6x));
    }

    .feature-cards-gallery>*:not(:first-child) {
        margin-left: var(--tds-size--3x);
    }

    [dir="rtl"] .feature-cards-gallery>*:not(:first-child) {
        margin-left: 0;
        margin-right: var(--tds-size--3x);
    }

    .feature-cards-gallery-buttons {
        --negative-arrows-margin: calc(var(--tds-size-4x) * -1);
    }

    .feature-cards-gallery-modal .tds-card .tds-card-body p,
    .tds-modal.modal-container--feature_cards_gallery_modal .description {
        font-size: calc(var(--tds-size--2x) + 1px);
        line-height: var(--tds-line-height-40);
    }

    .feature-cards-gallery-modal .tds-card .tds-card-body .tds-text--h2 {
        font-size: calc(var(--tds-size--4x) + 2px);
        padding-block-end: var(--tds-size--3x);
        padding-block-start: var(--tds-size--2x);
    }

    .feature-cards-gallery .tds-card .tds-card-asset .asset-loader-label {
        font-size: var(--tds-font-size-40);
    }
    .tds-modal.modal-container--feature_cards_gallery_modal .tds-tab-list {
        margin-block-start: var(--tds-size-4x);
        margin-block-end: var(--tds-size-5x);
    }
}

@media (min-width: 900px) {
    .feature-cards-gallery .tds-card {
        flex: 0 0 60%;
    }
}