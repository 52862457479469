.modal-container--referral_modal {
  width: 500px;
}
.modal-container--referral_modal:after {
  display: none !important;
}
.modal-container--referral_modal .modal-container--referral-modal .tds-modal-content .referral-check-mark {
  background-repeat: no-repeat;
  height: 50px;
}
.modal-container--referral_modal .modal-container--referral-modal .tds-modal-content .icon-check-mark {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMjUgMGgyNXY1MEgwVjB6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZmlsbD0iIzAyRTM1OSIgZD0iTTI0LjA3IDMyLjMwOGwtNy4xNDctNy4xMzMgMS43ODctMS43ODMgNS4zNiA1LjM1IDkuNTI4LTkuNTExIDEuNzg3IDEuNzgzeiIvPjxnPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjxwYXRoIGQ9Ik0yNSAwQzExLjE5MyAwIDAgMTEuMTkzIDAgMjVzMTEuMTkzIDI1IDI1IDI1IDI1LTExLjE5MyAyNS0yNVMzOC44MDcgMCAyNSAwbTAgMi41YzEyLjQwNiAwIDIyLjUgMTAuMDk0IDIyLjUgMjIuNSAwIDEyLjQwNy0xMC4wOTQgMjIuNS0yMi41IDIyLjVTMi41IDM3LjQwNyAyLjUgMjVDMi41IDEyLjU5NCAxMi41OTQgMi41IDI1IDIuNSIgZmlsbD0iIzAyRTM1OSIgbWFzaz0idXJsKCNiKSIvPjwvZz48L2c+PC9zdmc+);
}
.modal-container--referral_modal .modal-container--referral-modal .tds-modal-content .referral-modal--description {
  padding: var(--tds-size-3x) 0;
  color: var(--tds-color--grey30);
}
.modal-container--referral_modal .referral-modal--section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-container--referral_modal .referral-modal--section .referral-modal--title {
  padding-top: 0;
}
@media (max-width: 599px) {
  .modal-container--referral_modal .referral-modal--section {
    align-items: center;
    text-align: center;
    padding-top: var(--tds-size-10x);
  }
}
/* banner style */
.referral--block {
  padding: 10px var(--tds-size-3x);
}
.referral--block .referral--block-desc {
  margin: var(--tds-size-base) 0 0;
  color: var(--tds-color--grey30);
}
@media (min-width: 600px) {
  .referral--block {
    margin-top: 60px;
    margin-bottom: -100px;
  }
}
.i18n-country-CN .tds-theme--dk .modal-container--referral-modal .tds-modal-header, .i18n-country-CN .tds-scrim--black .modal-container--referral-modal .tds-modal-header {
  background: var(--tds-color--black);
}
