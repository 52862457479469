.custom-select-container {
  position: relative;
}
.custom-select-container .custom-select--input {
  justify-content: flex-end;
  cursor: pointer;
}
.custom-select-container .custom-select--input .select-input--label {
  flex-grow: 1;
}
.custom-select-container .custom-select--input .tds-icon {
  position: relative;
  top: -2px;
  left: 5px;
}
.custom-select-container .custom-dropdown--list {
  width: 100%;
  background-color: var(--tds-theme-background-dialog);
  padding: var(--tds-size-base) var(--tds-size-2x);
  position: absolute;
  z-index: 1;
}
.custom-select-container .custom-dropdown--list .tds-list {
  cursor: pointer;
  display: flex;
  margin: var(--tds-size-base) 0;
  color: var(--tds-theme-foreground);
}
.custom-select-container .custom-select--input--disabled {
  cursor: default;
}
.custom-select-container .custom-dropdown--section {
  width: 100%;
  z-index: 1;
}
.custom-select-container .tds-form-item {
  margin: 0;
}
.cf-form-custom-padding {
  margin-bottom: 0 !important;
}
.custom-select-container .custom-select--input {
  --tds-form--indent: 12px;
  block-size: auto;
  min-block-size: var(--tds-height--pill);
  outline-offset: 2px;
  padding-block: 0;
  padding-inline: var(--tds-form--indent);
  display: flex;
  background-color: var(--tds-color--grey70);
  border: none;
  padding: var(--tds-size-base) var(--tds-size-2x);
}
.custom-select-container .custom-dropdown--list {
  transition: opacity 0.5s var(--tds-bezier), transform 0.5s var(--tds-bezier), visibility 0s 0s;
  box-shadow: var(--tds-box-shadow--medium);
  border: none;
  border-radius: var(--tds-border-radius--card);
  margin-top: var(--tds-size-2x);
}
