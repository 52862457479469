.tds--vertical_margin--fieldset {
  margin-block-end: var(--tds-size-6x);
  margin-block-start: var(--tds-size-6x);
}

/* Group / Option Asset START */

.group-main-content--full-asset {
  height: 100%;
  max-height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.group-main-content--full-asset .asset-compositor--image {
  width: 100%;
  height: auto;
}

.group--main-content--asset {
  width: 100%;
  height: 100%;
}

.tds-option--circular {
  aspect-ratio: 1;
}

.tds-option--circular input.group--options_asset--container {
  width: 0;
  height: 0;
}

.group--options_asset--container,
.group--options_cardAddon {
  display: inline-block;
  cursor: pointer;
}

.group--option--label {
  cursor: pointer;
  display: inline-block;
}

.group--options_asset {
  margin: 0 1px;
  width: 54px;
}

.tds-form-input-group {
  text-align: left;
}

[dir=rtl] .tds-form-input-group {
  text-align: right;
}

/* Group / Option Asset END */

/* Option Details - Option Block START */

.group--options_block {
  border: 1px solid var(--tds-color--grey50);
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: flex-start;
  padding: 12px 20px;
  margin-bottom: 12px;
  flex-direction: column;
}

.group--options_block.group--option--selected {
  border-color: var(--tds-color--blue30);
  box-shadow: 0 0 0 2px var(--tds-color--blue30);
}

.group--options_block_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tds-form-input-group.group--options_block-container_choice_fullwidth > .tds-form-input:not(:last-child) {
  margin-block-end: var(--tds-size-2x);
}

.tds-form-input-group.group--options_block-container_choice_fullwidth .tds-form-input-choice-label {
  width: 100%;
}

.tds-form-input-group.group--options_block-container_choice_fullwidth .tds-form-input-choice-label .group--options_block-container_label_wrap {
  display: flex;
  flex-direction: row;
}

.group--options_block-container_price {
  text-align: right;
  white-space: nowrap;
}

.tds-o-inline-auto{
  inline-size: auto;
}

.group--options_block-container_price + .group--options_block-container_price {
  padding-block-start: 0;
  font-size: var(--tds-font-size-20);
}

.group--options_block.group--option--selected .group--options_block--name,
.group--options_block.group--option--selected .group--options_block-container_price {
  font-family: var(--tds-sans-font_stack--medium);
  color: var(--tds-color--black);
}

/* Locale-specific override */

.i18n-country-CA .group--main-container__BATTERY_AND_DRIVE .group--child-container .group--options_block .group--options_block-option_price:after,
.i18n-country-US .group--main-container__BATTERY_AND_DRIVE .group--child-container .group--options_block .group--options_block-option_price:after {
  content: "*";
}

.group--options_block--container {
  display: flex;
  flex-direction: column;
}

.group--options_block--container .tds-form-input-hidden-choice {
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
}

.group--options_block--container .tds-option-input:checked + .tds-option-label {
  box-shadow: inset 0 0 0 1px var(--tds-color--blue30);
  border: 2px solid var(--tds-color--blue30);
}

.group--options_block--container .tds-option-input:disabled + .tds-option-label.tds-o-option--disabled {
  background-color: var(--tds-color--grey70);
  border-color: var(--tds-color--grey70);
  box-shadow: var(--tds-color--grey70);
}

/* Option Details - Option Block END */

/* Option Details - Option Asset / Option cardAddon START */

.group-option--detail-container_name {
  color: var(--tds-color--black);
  margin-inline-end: 10px;
  white-space: nowrap;
}

.coin-reloaded .group-option--detail-container_name {
  color: var(--tds-o-option-name-color);
  margin-inline-end: 0;
}

/* Mobile devices */

@media (max-width: 599px) {
  body:not(.coin-reloaded) .child-group--selected_option_details .custom-content {
    flex-flow: column;
  }
  .child-group--selected_option_details .custom-content .group-option--detail-container_name {
    margin-inline-end: 0;
  }
  .option-widget:first-child .tds--vertical_margin--fieldset.option-widget--container {
    margin-block-start: var(--tds-size-4x);
  }
}

/* Option Details - Option Asset / Option cardAddon END */

/* Option Details - Option Circle START */

.group--detail-container .tds-radio-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  opacity: 0;
  width: 0;
}

.group--detail-container .tds-flex {
  display: flex;
  flex-direction: row;
}

.group--detail-container .tds-radio {
  position: relative;
  margin-bottom: var(--tds-radio_buttons-vertical_spacer);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
}

.group--options_circle--container .tds-radio-input:checked + .tds-label .group-option_circle-content::after {
  box-shadow: 0 0 0 0px var(--tds-color--white), 0 0 0 3px var(--tds-color--blue30);
  margin: -4px;
}

.coin-reloaded .group--options_circle--container .tds-radio-input:checked + .tds-label .group-option_circle-content::after {
  box-shadow: 0 0 0 5px #EDEDED inset, 0 0 0 1px var(--tds-color--black);
  margin: -4px;
}

.group--options_circle--container .tds-radio_buttons {
  padding: 8px 10px;
}

.group--options_circle--container .group-option_circle-label {
  padding: 7px 0;
  width: 40px;
  height: 40px;
  background-color: var(--tds-color--grey70);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.group--options_circle--container .group-option_circle-content::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
  transition: box-shadow 0.3s ease;
}

.group--options_circle--container .group-option_circle-title {
  font-family: var(--tds-font-combined--medium);
  font-size: var(--tds-size-3x);
  color: var(--tds-color--grey10);
  line-height: 28px;
  font-weight: 600;
}

.group--options_circle--container .tds-label--radio:before,
.group--options_circle--container .tds-label--radio:after {
  display: none;
}

/* Option Details - Option Circle END */

.i18n-country-CN .review-page--container .tds-form-fieldset .tds-radio_buttons .tds-radio {
  display: inline-block;
  margin-top: var(--tds-size-half);
}

.i18n-country-CN .review-page--container .tds-form-fieldset .tds-radio_buttons .tds-radio:first-of-type {
  margin-right: 40px;
}

.i18n-locale-de_CH.i18n-model-m3 .tds-option-label .tds-label-title .group--options_block--name,
.i18n-locale-de_CH.i18n-model-m3 .tds-option-label .tds-label-descriptor .group--options_block-container_price, .i18n-locale-fr_CH.i18n-model-m3 .tds-option-label .tds-label-title .group--options_block--name,
.i18n-locale-fr_CH.i18n-model-m3 .tds-option-label .tds-label-descriptor .group--options_block-container_price {
  font-size: calc(var(--tds-font-size-30) - 1px);
}

.cfg-fixed-height-swatch {
  height: 92px !important;
}

@media (max-width: 600px) {
  .cfg-fixed-height-swatch {
    height: 113px !important;
  }
}

.energy-label {
  max-width: 150px;
}

.option-widget--container.tds-form-fieldset, .option-widget--container.tds-form-layout {
  gap: 0;
}

.option-widget--container .tds-text--caption--container {
  padding-block-start: var(--tds-size-base);
  padding-block-end: var(--tds-size-base);
  padding-top: var(--tds-size-base);
  padding-bottom: var(--tds-size-base);
}

.tds-form-input-group .tds-o-option .trim-option .trim-option-label {
  display: block;
}

.tds-form-input-group .tds-o-option:not(:last-child) .trim-option .trim-option-label {
  margin-bottom: 12px;
}

.trim-option .trim-option-items {
  padding: var(--tds-size-2x);
}

.trim-option .trim-option-title, .trim-option .trim-option-price {
  padding: 0;
  color: var(--tds-theme-foreground-low-contrast);
  line-height: normal;
}

.trim-option .trim-option-price {
  min-inline-size: -moz-min-content;
  min-inline-size: min-content;
}

.trim-option .drive-mode.drive-mode-with-short-name {
  padding-top: var(--tds-size-half);
}

.trim-option .drive-mode.drive-mode-newline {
  display: block;
}

@media (min-width: 1025px) {
  .trim-option .drive-mode {
    display: block;
  }
}

.trim-option .after-savings-label, .trim-option .after-savings-value, .trim-option .trim-features-link {
  font-weight: normal;
}

.trim-option .trim-features-link {
  font-size: var(--tds-font-size-20);
}

.trim-option .specs-and-features {
  margin-top: var(--tds-font-size-20);
  font-size: var(--tds-font-size-30);
}

.trim-option .specs-and-features .spec-label {
  font-weight: normal;
  line-height: var(--tds-line-height-50);
}

.trim-option .specs-and-features .spec-value {
  font-weight: var(--tds-font-weight-medium);
  line-height: var(--tds-line-height-50);
}

.trim-option .trim-features-link-wrapper {
  margin-top: var(--tds-font-size-20);
  padding-bottom: var(--tds-border-width--medium);
}

.trim-option .trim-features-list {
  font-size: var(--tds-font-size-20);
  font-weight: normal;
  margin-top: var(--tds-font-size-20);
}

.trim-option .trim-features-list li {
  line-height: var(--tds-size-2x);
  padding: var(--tds-border-width--medium) 0;
  text-wrap: balance;
}

.trim-option .trim-features-list li:first-of-type {
  padding-top: 0;
}

.trim-option .trim-features-list li:last-of-type {
  padding-bottom: 0;
}

.trim-option .selected-trim .trim-option-title, .trim-option .selected-trim .trim-option-price {
  color: var(--tds-theme-foreground-high-contrast);
}

.trim-option .trim--collapsible-section {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.trim-option .trim-badge,
.tds-modal-content .trim-badge {
  display: block;
  block-size: initial;
  min-inline-size: auto;
}

.tds-modal-content .trim-badge {
  margin: var(--tds-size--2x) 0;
}

.trim-option .selected-trim .trim-name {
  color: #5e6165;
}

.trim-option .trim-name,
.tds-scrim--dk .trim-option .trim-name,
.tds-scrim--dk .trim-option .trim-delivery-info {
  color: #7d7e81;
}

.trim-option [data-action-trigger='SHOW_FEATURE_CARDS_GALLERY_MODAL'] {
  white-space: nowrap;
}

.trim-option .trim-badge {
  opacity: 0.5;
  margin: var(--tds-size) 0;
}

.selected-trim .trim-badge {
  opacity: 1;
}

.trim-delivery-info {
  font-weight: 500;
  color: #5c5e62;
}

.selected-trim .trim-delivery-info {
  color: #393c41;
}

.trim-name {
  font-size: var(--tds-font-size-20);
  display: inline-block;
}

.trim-description {
  font-size: var(--tds-font-size-30);
  font-weight: normal;
  margin-top: var(--tds-size--half);
  color: #707070;
}
.trim-description + .tds-link {
  margin-left: var(--tds-size-half);
  font-weight: normal;
}

@media (max-width: 599px) {
  .trim-option .trim-option-title, .trim-option .after-savings-label {
    flex: 60%;
    inline-size: 60%;
    min-inline-size: unset;
  }
  .trim-option .trim-option-price, .trim-option .after-savings-value {
    flex: 30%;
    inline-size: 30%;
    min-inline-size: unset;
  }
  .trim-option .trim-option-title, .trim-option .trim-option-price {
    font-size: var(--tds-font-size-30);
  }
}

.trim-caption:not(:last-child) {
  margin-bottom: var(--tds-size-base);
}

.trims-caption-prefix {
  padding-inline-end: var(--tds-border-width--medium);
}

.text-loader--subtitle + .group--child-container .observer-placeholder:first-child .trim-option {
  margin-top: var(--tds-size-2x);
}
body:not(.coin-reloaded) .group-section--container .option-widget--container .tds-form-input-group .tds-o-option .tds-form-input-hidden-choice + .tds-o-option-label {
  margin-block-end: var(--tds-size-1xhalf);
}
.coin-reloaded .group-section--container .option-widget--container .tds-form-input-group .tds-o-option:not(:last-child) .tds-form-input-hidden-choice + .tds-o-option-label {
  margin-block-end: var(--tds-size-1xhalf);
}
.group--child-container .group--detail-container .observer-placeholder.tds-option:empty {
  margin-bottom: 0;
}
.coin-reloaded .formatted-price,
.coin-reloaded:not(.tds-theme--replicant-v2) .group-option--price {
  color: var(--tds-base-text-color);
  font-weight: 500;
}
.list-option--padding,
.coin-reloaded .accessories-list-option {
  padding: var(--tds-size-2x) calc(var(--tds-size-2x) - var(--tds-size-half));
}
.coin-reloaded .accessories-asset--container {
  width: 120px;
  height: 100px;
}
.coin-reloaded .accessories-block-wtih-asset {
  flex-direction: row;
  justify-content: center;
}

.coin-reloaded .accessories-block-wtih-asset .accessories-list-option {
  padding: calc(var(--tds-size-1x) + var(--tds-size-1x) / 2);
  margin-block-end: 0 !important;
}

.coin-reloaded .accessories-list-option .tds-form-input-leading {
  display: flex;
}

.coin-reloaded .accessories-block-wtih-asset > .accessories-list-option:not(:last-child) {
  margin-inline-end: var(--tds-size-3x);
}

.coin-reloaded .accessories-block-wtih-asset .accessories-list-option .tds-form-input-leading {
  padding-inline-end: 0;
  margin-inline-end: 0;
  z-index: 1;
  position: absolute;
}

.coin-reloaded .accessories-block-wtih-asset .accessories-list-option .accessories-list-option--label {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 160px;
}

.coin-reloaded .accessories-block-wtih-asset .tds-form-input-hidden-choice:checked + .tds-form-input {
  font-weight: var(--tds-font-weight-regular);
}

.coin-reloaded .tds-form-input-hidden-choice + .tds-form-input.tds-form-input--option .selector-option--title {
  color: var(--tds-theme-foreground-low-contrast);
  font-weight: 500 !important;
}
.coin-reloaded .tds-form-input-hidden-choice:checked + .tds-form-input.tds-form-input--option .selector-option--title {
  color: var(--tds-theme-foreground-high-contrast);
}
.coin-reloaded .tds-form-input-hidden-choice + .tds-form-input.tds-form-input--option .selector-option--caption {
  font-size: var(--tds-font-size-20);
  line-height: var(--tds-line-height-10);
  font-weight: 400 !important;
}
.coin-reloaded .feature-group-container .feature-group--items.feature-description .text-loader--description {
  color: var(--tds-theme-foreground-low-contrast);
}

.coin-reloaded .sport-package {
  padding-top: var(--tds-size-2x);
}

.coin-reloaded .sport-package h2 {
  padding: 0;
}

.coin-reloaded .trim-caption-container + .trim-caption-container,
.coin-reloaded .group--detail-container + .trim-caption-container {
  padding-block-start: var(--tds-size-2x);
}

.coin-reloaded .trim-caption-container + .incentives-toggle--container,
.coin-reloaded .tds-form-input-group + .ex-vat-disclaimer {
  padding-block-start: var(--tds-size-1x) !important;
}