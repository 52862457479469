.feature-table--section td {
  padding: 0;
  padding-left: var(--tds-size-3x);
}
@media (min-width: 600px) {
  .feature-table--section td.tds-o-grid-label {
    width: 50%;
  }
  .feature-table--section td.tds-o-grid-value {
    padding-left: var(--tds-size-base);
  }
}
@media (max-width: 599px) {
  .feature-table--section td.tds-o-grid-label {
    width: 59%;
  }
  .feature-table--section td.tds-o-grid-value {
    padding-left: 0;
  }
}
.dynamic-grid--space-evenly {
  width: 100%;
}
@media (min-width: 600px) {
  .dynamic-grid--space-evenly .feature-table--section td.tds-o-grid-label {
    width: 24%;
  }
}
.features-copy--disclaimer {
  padding-top: var(--tds-size-base);
}
.groupComponent-gradient {
  position: absolute;
  bottom: 0;
  width: calc(var(--carousel-panel-width) - 2 * var(--tds-size-2x));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 33%, rgb(255, 255, 255) 100%);
  height: var(--tds-size-10x);
  border-radius: var(--tds-size-2x);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 599px) {
  .groupComponent-gradient {
    height: var(--tds-size-7x);
  }
}
/**
* Firefox only styles, because FF doesn't take into account padding
* while computing scroll height 
* TODO - move away from ID based styles
*/
@-moz-document url-prefix() {
  #COMPARE_MODEL_AND_FEATURE-panel .feature-group {
    display: block;
  }
  #COMPARE_MODEL_AND_FEATURE-panel .feature-group .feature-group-container {
    position: relative;
    margin-bottom: 80px;
  }
  #SR_INTERIOR_FEATURES_CARD-panel h3 + div:first-of-type,
  #LR_INTERIOR_FEATURES_CARD-panel h3 + div:first-of-type,
  #INTERIOR_FEATURES_CARD-panel h3 + div:first-of-type {
    margin-bottom: 80px;
  }
}
.info-block-buttons.info-block-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--tds-size-2x);
}
.info-block-buttons.info-block-grid .grid-item-1 {
  grid-row-end: none;
}
body:not(.coin-reloaded) .vehicleSummary-info-block {
  padding-block-start: var(--tds-size-7x);
}
.post-order-swap-confirmation-container {
  height: 70vh;
  flex-direction: column;
}
.post-order-swap-confirmation-container .icon-confirmation--success {
  color: #02AD5D;
  margin: auto;
  --tds-icon--size: var(--tds-size-7x);
}
.post-order-swap-confirmation-container .confirmation-title {
  padding-top: var(--tds-size-2x);
}
.post-order-swap-confirmation-container .continue-btn {
  min-width: 300px;
}
.result-gallery-chip-container {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--tds-color-grey-70);
  border-radius: var(--tds-border-radius--pill);
  padding-inline-start: var(--tds-size-2x);
  padding-inline-end: var(--tds-size-base);
  padding-block: 6px;
}
.result-gallery-chip-container .chip-icon--info {
  --tds-icon--size: var(--tds-size-2x);
}
.tds-o-chip {
  min-width: fit-content;
  block-size: auto;
}

.fsd-only-modal #feature-group-EXPLORE_FEATURES-content-selector,
.fsd-only-modal .feature-panel-name,
.fsd-only-title {
  display: none;
}

.fsd-only-modal .feature-description .fsd-only-title {
  display: block;
  font-size: var(--tds-font-size-70);
  line-height: var(--tds-line-height-70);
}

@media (max-width: 599px) {
  .fsd-only-modal .feature-description .fsd-only-title {
    font-size: var(--tds-font-size-60);
    line-height: var(--tds-line-height-60)
  }
}