.tds-o-select-input > .tds-select-input {
  padding: var(--tds-select-padding_top) 50px var(--tds-select-padding_bottom) var(--tds-select-horizontal_spacer) !important;
}

.tds-o-select-input > .tds-icon {
  right: calc(3 * var(--tds-select-horizontal_spacer));
}

.tds-o-form-input-wrap--dropdown {
  display: flex;
  padding-inline-end: var(--tds-size-base);
  padding-inline-start: calc(var(--tds-size-base) + 4px);
}

.i18n-locale-zh_TW .tds-o-form-input-wrap--dropdown {
  padding-inline-start: var(--tds-size-base);
}

.i18n-country-PR .tds-o-form-input-wrap--dropdown {
  padding-inline-end: calc(var(--tds-size-2x) - 2px);
  padding-inline-start: var(--tds-size-base);
}

.i18n-country-PR .tds-o-form-input-wrap--dropdown .tds-form-input-trailing svg {
  margin-inline-end: 0;
}
