.specs-block {
  display: flex;
  flex-direction: column;
  /* Our tablet starts at 1024px for iPad portrait view */
  /* Mobile devices */
}
.specs-block .emissions-item {
  padding: 0 var(--tds-size-2x);
}
.specs-block .emissions-item-container {
  width: 100%;
  overflow-wrap: break-word;
}
.specs-block .inventory-emissions {
  text-align: center;
}
.specs-block .text-loader--range-info {
  text-align: center;
}
.specs-block .text-loader--range-info, .specs-block .text-loader--range-info:hover {
  box-shadow: none;
}
.specs-block .text-loader--range-info span {
  position: relative;
  top: -2px;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}
.specs-block .text-loader--range-info span:hover, .specs-block .text-loader--range-info span:active {
  box-shadow: 0 var(--tds-border-width--medium) 0 0 currentColor;
}
.specs-block .tds-list-item {
  max-width: -moz-min-content;
  max-width: min-content;
  min-width: 32%;
  margin-right: 0;
  margin-bottom: 0;
  text-align: center;
}
.specs-block .tds-list-item .tds-list-item_title {
  padding-bottom: var(--tds-size);
  margin-bottom: 0;
}
.specs-block .tds-list-item .tds-list-item_title .specs--value-label {
  font-size: var(--tds-base-font_size);
  line-height: var(--tds-size-3x);
  padding-left: calc(var(--tds-size-base) / 4);
}
[dir=rtl] .specs-block .tds-list-item .tds-list-item_title .specs--value-label {
  padding-left: initial;
  padding-right: calc(var(--tds-size-base) / 4);
}
.i18n-language-zh .specs-block .tds-list-item .tds-list-item_title .specs--value-label {
  font-size: var(--tds-font-size-20);
}
.i18n-language-el .specs-block .tds-list-item .tds-list-item_title .specs--value-label {
  white-space: nowrap;
}
.specs-block .tds-o-list--box {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.specs-block .tds-list-item_title {
  color: var(--tds-text--color);
}
.specs-block .cf-range-est--container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  /* TODO: Implement a better solution. Padding override is neede because of .tds-list-item overrides */
}
.specs-block .cf-range-est--container .tds-o-btn--link {
  color: inherit;
  padding-bottom: 2px;
  box-shadow: none;
  block-size: auto;
}
.specs-block .cf-range-est--container .tds-o-btn--link span {
  transition: box-shadow 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75), color 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}
.specs-block .cf-range-est--container .tds-o-btn--link span:hover {
  color: var(--tds-color--black);
  transition: box-shadow 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75), color 0.25s cubic-bezier(0.5, 0.25, 0.25, 0.75);
  box-shadow: 0 var(--tds-border-width--medium) 0 0 currentColor;
}
.specs-block .tds-o-list--box {
  display: flex;
  align-items: baseline;
  flex: 1;
  overflow-x: auto;
  justify-content: space-around;
}
.specs-block .tds-o-list--box .tds-o-list-item {
  margin: 0;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .specs-block .tds-o-list--box {
    justify-content: space-around;
  }
}
@media (max-width: 599px) {
  .specs-block .tds-o-list--box {
    justify-content: space-around;
  }
}
html:not(.tds-theme--cyberpunk ) .vehicle-summary-container .specs-block .tds-o-list--box .tds-list-item .tds-list-item_title {
  line-height: var(--tds-line-height-70);
  padding-bottom: 0;
}
.top-speed-disclaimer-link {
  text-align: center;
  display: inline-block;
  text-decoration: underline;
  box-shadow: none !important;
  line-height: var(--tds-line-height-30);
}
.top-speed-disclaimer-link:hover {
  box-shadow: none !important;
}
.inventory-range-modal-btn {
  text-decoration: underline;
}
