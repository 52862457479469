.location-modal .location-modal--title {
  padding-block-start: 0;
  padding-block-end: 0;
}
.location-modal .location-modal--button {
  display: block;
  width: 204px;
}
.location-modal .tds-form-item {
  padding-top: var(--tds-size-3x);
  padding-bottom: 0;
}
@media (min-width: 599px) {
  .location-modal .location-modal--action {
    margin-block-start: var(--tds-size-5x);
  }
}
@media (max-width: 600px) {
  .location-modal .location-modal--button {
    width: auto;
  }
}
.cf-shell-content .deliveryZip--link {
  text-decoration: none;
  border: none;
  box-shadow: none;
}
.modal-container--user_zip_code_modal .tds-modal-content {
  padding-left: var(--tds-size-6x);
  padding-right: var(--tds-size-6x);
}
.modal-container--user_zip_code_modal .modal-container--userlocation-modal {
  margin-top: var(--tds-size-base);
}
.modal-container--user_zip_code_modal .tds-modal-content:last-child:after {
  height: var(--tds-size-6x);
}
