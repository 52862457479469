.feature-group-selector--container {
  max-height: 29px;
  position: relative;
  z-index: 8;
  display: flex;
}
.feature-copy-container .feature-group-selector--container {
  max-height: fit-content;
}
.feature-copy-container .feature-group-selector--container .tds-tab-list {
  margin-bottom: var(--tds-size-3x);
}
.feature-group-selector--container .feature-group-selector--button {
  display: flex;
}
.feature-group-selector--container .feature-group-selector--button:focus {
  outline: 1px solid lightblue;
}
.feature-group-selector--container ~ .financial--form-container, .feature-group-selector--container ~ .feature-group--items {
  transition: filter 0.5s ease;
  margin: var(--tds-size-3x) 0;
}
.feature-group-selector--container .tds-icon {
  transition: transform 0.5s var(--bezier);
  transform: rotateX(0);
  margin-right: var(--tds-size-base);
}
.feature-group-selector--container .tds--is_visually_hidden:not(:focus):not(:active) {
  margin-right: var(--tds-size-base) !important;
  position: relative !important;
}
.feature-group-selector--container .tds-icon.flipped {
  transform: rotateX(180deg);
}
.feature-group--modal .feature-group-selector--button {
  margin-left: -5px;
}
.feature-group-selector--container.open .feature-group-selector--button {
  cursor: pointer;
}
.feature-group-selector--container.open ~ .financial--form-container, .feature-group-selector--container.open ~ .feature-group--items {
  filter: blur(16px);
  overflow: visible;
}
.feature-group-selector--container.open .feature-group-selector--button {
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  align-items: baseline;
}
.feature-group-selector--container.open .feature-group-selector--options {
  display: inline-block;
  width: auto;
  min-width: 120px;
  height: auto;
  overflow: initial;
  z-index: 100;
  opacity: 1;
  padding: 0;
  margin-bottom: 0;
  transition: all 0.5s ease;
  -webkit-transition: none;
  position: relative;
  margin: 0;
}
[dir=rtl] .feature-group-selector--container.open .feature-group-selector--options {
  right: 10px;
  left: initial;
}
.feature-group-selector--container.open.modal-content--sidebar .feature-group-selector--options {
  transition: opacity 0.5s ease;
}
.feature-group-selector--container.open .feature-group-selector--option {
  height: initial;
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: 0;
}
.feature-group-selector--container.open .feature-group-selector--option h3 {
  padding: 0;
}
.feature-group-selector--options {
  cursor: pointer;
  flex-direction: column;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  border-radius: 10px;
}
.feature-group-selector--option {
  color: var(--tds-color--grey30);
  font-family: var(--tds-sans-font_stack--book);
  font-size: 16px;
  padding: 13px 0;
  text-align: left;
  min-width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0s;
}
.feature-group-selector--option:hover {
  font-family: var(--tds-sans-font_stack--medium);
}
.feature-group-selector--option:first-child {
  padding: 0 0 10px 0;
}
.feature-group-selector--option::after {
  display: block;
  content: attr(data-label);
  font-family: var(--tds-sans-font_stack--medium);
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
.feature-group-selector--option h3 {
  color: var(--tds-color--grey30);
}
.feature-group-selector--option.selected h3 {
  font-weight: var(--tds-text--bold);
  color: var(--tds-color--grey10);
  font-family: var(--tds-font-family-base);
  font-weight: var(--tds-font-weight-medium);
}
.groupComponent--container {
  padding: var(--tds-size-6x) var(--tds-size-4x) var(--tds-size-10x) var(--tds-size-4x);
  max-height: var(--carousel-panel-height);
  overflow-y: auto;
}
.groupComponent--container .text-loader--main_title {
  color: var(--tds-heading--color);
  font-weight: 500;
  font-family: var(--tds-font-family-base);
  font-weight: var(--tds-font-weight-medium);
}
.warranty-block-container .text-loader--no-padding-top, .vehicleDetails-block.container .text-loader--no-padding-top {
  padding-top: 0;
}
.vehicleDetails-block-container .vehicleDetails-inspection--btn {
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.feature-group--items .groupComponent--container {
  padding: 0;
}
.feature-group--items .groupComponent--container .tds-flex-gutters .tds-flex--col_1of2 {
  padding-top: 0 !important;
}
.feature-group--items .groupComponent--container .text-loader--subtitle {
  font-size: 12px;
}
.feature-group--items .tds-flex--container {
  flex-flow: inherit;
}
.feature-group-selector--container .feature-group-selector--button:focus,
.feature-group-selector--container .feature-group-selector--option:focus {
  box-shadow: inset 0 0 0 1px var(--tds-color--blue30);
}
.using-mouse .feature-group-selector--container .feature-group-selector--button:focus,
.using-mouse .feature-group-selector--container svg.tds-icon:focus {
  box-shadow: none;
  outline: none;
}
@media (min-width: 599px) {
  .feature-group-selector--container:not(.modal-content--sidebar) {
    margin-left: 12px;
  }
}
@media (max-width: 599px) {
  .feature-group--items {
    padding-top: 0;
  }
  .feature-group--items .tds-flex--container {
    display: inline-block;
  }
  .feature-group-selector--container {
    margin-bottom: var(--tds-size-base);
  }
  .feature-group-selector--container .feature-group-selector--button {
    padding-right: 0;
  }
  .groupComponent--container {
    padding: var(--tds-size-5x) var(--tds-size-3x) var(--tds-size-10x) var(--tds-size-3x);
    height: 90vh;
    height: 90dvh;
  }
}
.feature-group-selector--label {
  display: flex;
  min-width: 100%;
  flex-direction: row;
}
.feature-group {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1025px) {
  .feature-group {
    overflow-y: auto;
  }
}

.feature-group-selector--container .tds-tab {
  padding: var(--tds-size-half) var(--tds-size-2x);
}