.legal-disclaimer,
.finance-disclaimer {
  margin-block-start: var(--tds-size-2x);
}
.legal-disclaimer.legal-disclaimer--condensed,
.finance-disclaimer.finance-disclaimer--condensed {
  margin-block-start: 0;
}

.extradisclaimer p,
.finance-disclaimer p,
.priceAcceptance-disclaimer p {
  color: var(--tds-color--grey30);
  font-size: var(--tds-font-size-20);
}

.finance-disclaimer p {
  margin: var(--tds-size-2x) 0;
}

.summary-container--finance-disclaimer p {
  font-size: var(--tds-font-size-20);
}

.footer-disclaimer {
  text-align: center;
  padding: 0 10%;
}

.fsdDisclaimer--margin-bottom {
  margin-block-end: var(--tds-size-2x);
}

.inventorySwapDeliveryDisclaimer-margin-bottom {
  margin-block-end: var(--tds-size-2x);
}

.info-block-additional-info {
  padding-block-start: var(--tds-size-base);
}

.deliveryAndRegistrationDisclaimer {
  display: flex;
}

.deliveryAndRegistrationDisclaimer .deliveryAndRegistrationDisclaimerIcon {
  width: var(--tds-size-4x) !important;
  height: var(--tds-size-4x) !important;
  position: relative;
  top: 10px;
}

.deliveryAndRegistrationDisclaimer .deliveryAndRegistrationDisclaimerText {
  text-align: start;
}

.paymentDeliveryAndRegistrationDisclaimer {
  margin: var(--tds-size-2x) 0;
}

.legal-data-table table td:first-child {
  padding-right: 15px;
  word-break: keep-all;
}

.email-validation-disclaimer {
  margin-block-end: var(--tds-size-base);
}

.icon-disclaimer {
  display: flex;
  flex-direction: "row";
  gap: var(--tds-size-base);
  align-items: center;
}

/* overriding for ios issue */

html:not(.tds-theme--cyberpunk) .payment-order-button {
  background-color: var(--tds-color--primary);
  border: inherit;
}

@media (max-width: 599px) {
  .footer-disclaimer {
    padding: 0;
  }
}

.legal-disclaimer.preprod-disclaimer-disclaimer .preprod-disclaimer-button {
  display: contents;
}

.legal-disclaimer a {
  -webkit-box-decoration-break: clone;
}

.legal-disclaimer .tds-form-item {
  padding-block-start: var(--tds-size-base);
  padding-block-end: var(--tds-size-base);
}

.coin-component--InventoryUpsellLink {
  margin-block-end: var(--tds-size-2x);
  margin-bottom: var(--tds-size-2x);
  display: inline-block;
}

.tds-card.coin-card--highlighted {
  background-color: var(--tds-color--white);
  margin-top: var(--tds-size-base);
}

.tds-scrim--black .tds-card.coin-card--highlighted {
  background-color: var(--tds-color--grey15);
}

.tds-card.tds-scrim--black {
  background-color: var(--tds-color--grey15);
}

.tds-card.tds-scrim--black .tds-o-text_color--20 {
  color: var(--tds-text--color);
}

.tds-card.coin-prominent--currency {
  margin-top: var(--tds-size-base);
  background: rgb(34, 34, 34);
  background: linear-gradient(0deg, rgb(34, 34, 34) 0%, rgb(58, 58, 58) 100%);
}

.coin-banner-title {
  line-height: var(--tds-line-height-40);
  padding: 2px;
}

.tds-card.tds-scrim--gradient {
  /* safari 5.1+,chrome 10+ */
  /* opera 11.10+ */
  /* ie 10+ */
  /* global 92%+ browsers support */
  background: radial-gradient(ellipse at 50% -50%, #6c94ff 0%, #000000 100%);
  color: var(--tds-text--white);
}

.tds-card.tds-scrim--gradient .tds-text--caption {
  color: var(--tds-color-white);
}

.tds-card.tds-scrim--gradient .tds-text--caption.federal-tax--link a {
  box-shadow: 0 var(--tds-border-width--small) 0 0 currentColor;
}

.tds-card.tds-scrim--gradient .coin-banner-font--color {
  color: var(--tds-color-white);
}

.tds-card.tds-scrim--gradient .tds-link {
  color: var(--tds-color--white);
  font-size: var(--tds-font-size-20);
}

.tds-card.tds-scrim--gradient .federal-tax--description {
  padding-top: 6px;
}

.tds-card.tds-scrim--gradient .federal-tax--description .tds-link:is(:hover, .tds--hover):not(.tds-link--primary, .tds-link--vertical):not([disabled], .tds--disabled) {
  color: var(--tds-color--white);
}

.tds-card.tds-scrim--gradient .federal-tax--description .modal-trigger-container,
.tds-card.tds-scrim--gradient .federal-tax--description .modal-trigger,
.tds-card.tds-scrim--gradient .federal-tax--description .federal-tax--link {
  text-align: center;
}

.tds-card.tds-scrim--gradient .federal-tax--copy span {
  text-align: center;
  display: block;
}

.tds-card.tds-scrim--gradient div.action-trigger--link {
  display: inline;
}

.tds-card.tds-scrim--gradient.coin-card--multibanner .coin-banner-title {
  font-size: var(--tds-font-size-30);
}

.tds-card.tds-scrim--gradient.coin-card--multibanner .coin-banner-title:not(:first-child) {
  padding-block-start: var(--tds-size-2x);
  padding-bottom: var(--tds-size-half);
}

.tds-card.tds-scrim--gradient.coin-card--multibanner .modal-trigger-container {
  padding-top: var(--tds-size-base);
  text-align: left;
}

.tds-card.tds-scrim--gradient.coin-card--multibanner .federal-tax--copy span {
  text-align: left;
}

.top-banner {
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  color: var(--tds-color-grey-10);
  padding: var(--tds-size-2x) var(--tds-size-6x);
  line-height: calc(var(--tds-line-height-30) - var(--tds-border-width-medium));
  z-index: 2;
  transition: top 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.top-banner--container {
  z-index: 1;
}

.top-banner--container .top-banner--secondary {
  top: var(--coin-top-banner-height) !important;
  padding-top: 0 !important;
}

.has-top-banner .top-banner {
  display: flex;
}

.has-top-banner--hidden .top-banner {
  top: calc(-1.1 * var(--coin-top-banner-height)) !important;
}

.banner-modal-btn {
  padding-left: var(--tds-size-3x);
  padding-right: var(--tds-size-3x);
}

.main-page--tablet .top-banner {
  padding-left: calc(var(--tds-size-half) + var(--tds-size-4x));
  padding-right: calc(var(--tds-size-half) + var(--tds-size-4x));
}

.has-top-banner .site-header-container {
  transition-delay: -0.25s;
  top: var(--coin-top-banner-height);
}

.has-secondary-top-banner .site-header-container {
  transition-delay: -0.25s;
  top: calc(var(--coin-top-banner-height) * 2 - var(--tds-size-2x));
}

.has-top-banner--hidden .site-header-container {
  top: 0;
}

.tds-theme--dk .top-banner {
  background: var(--tds-color-grey-10);

  .text-loader--content,
  .modal-trigger-container {
    color: var(--tds-theme-foreground-high-contrast);
  }
}

.tds-theme--dk .tcl-banner .tds-banner {
  background-color: var(--tds-color-grey-10);
}

.top-banner .text-loader--content,
.top-banner .tds-link,
.top-banner a,
.top-banner .tds-link:hover,
.top-banner a:hover {
  color: var(--tds-color-grey-10);
}

.banner-modal-btn {
  background: var(--tds-theme-background-container);
  color: var(--tds-theme-foreground);
  box-shadow: none;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .top-banner:not(.tcl-banner) {
    justify-content: space-between;
    padding-left: calc(var(--tds-size-half) + var(--tds-size-4x));
    padding-right: calc(var(--tds-size-half) + var(--tds-size-4x));
  }
}

@media (max-width: 599px) {
  .top-banner:not(.tcl-banner) {
    justify-content: space-between;
    padding-left: var(--tds-size-3x);
    padding-right: var(--tds-size-3x);
  }
}

@media (min-width: 1025px) or (orientation: landscape) {
  html:not(.tds-theme--enterprise) .has-top-banner [data-group-id="BATTERY_AND_DRIVE"],
  html:not(.tds-theme--enterprise) .has-top-banner .inventory-group-section {
    margin-top: var(--tds-shell-header-height);
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  html:not(.tds-theme--enterprise) .has-top-banner [data-group-id="BATTERY_AND_DRIVE"],
  html:not(.tds-theme--enterprise) .has-top-banner .inventory-group-section {
      margin-top: 0;
  }
}

.tds-btn--tertiary.banner-modal-btn:hover {
  box-shadow: none;
  background: var(--tds-color--tertiary-highlight);
}

.has-top-banner .main-page--mobile .tds-modal.modal-container--banner_modal {
  min-block-size: auto !important;
}

.has-top-banner .main-page--mobile [data-group-id="BATTERY_AND_DRIVE"],
.has-top-banner .main-page--mobile .inventory-group-section,
.has-top-banner .main-page--tablet [data-group-id="BATTERY_AND_DRIVE"],
.has-top-banner .main-page--tablet .inventory-group-section {
  margin-top: 0;
}

.tds-theme--enterprise .top-banner .text-loader--content,
.tds-theme--enterprise .text-loader--content.banner-modal-btn {
  width: auto;
}

.tds-theme--enterprise .has-top-banner:not(.has-top-banner--hidden) .gallery {
  margin-top: var(--coin-top-banner-height);
}

.top-banner .tcl-banner-carousel .tds-banner {
  padding: var(--tds-size) var(--tds-size-3x);
}

.top-banner.tcl-banner {
  padding: 0;
}

@media (max-width: 599px) {
  .tcl-banner-carousel ul {
    justify-content: flex-start;
  }

  .tcl-banner-carousel ul {
    flex-basis: 90%;
  }

  .tcl-banner-carousel nav {
    flex-basis: 10%;
  }

  .tcl-banner-carousel .tds-link span {
    white-space: nowrap;
  }
}

@media (min-width: 600px) {
  .tcl-banner__carousel .tds-btn--small {
    max-width: unset;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .tds-shell {
    grid-template-rows: 44px 1fr var(--tds-shell-footer-height);
  }
}
