.autocomplete-dropdown {
  margin-top: calc(-1 * var(--tds-form--gap));
  z-index: 1;
  box-shadow: var(--tds-box-shadow--large);
  border-radius: var(--tds-size-2x);
  background: var(--tds-theme-background-dialog);
}
.autocomplete-dropdown li {
  color: var(--tds-theme-foreground);
  cursor: pointer;
  line-height: 20px;
  overflow: hidden;
  padding: 6px var(--tds-size-2x);
  text-overflow: ellipsis;
  white-space: pre-line;
}
.autocomplete-dropdown li:hover {
  background: var(--tds-theme-background-container-highlight);
}
.autocomplete-dropdown li.autocompleteSelected {
  background: #eee;
}
.autocomplete-dropdown {
  border-radius: var(--tds-border-radius--pill);
}
.tds-o-custom-address {
  padding-inline-start: var(--tds-size-4x);
  padding-block-end: var(--tds-size-2x);
}
.tds-o-radio-input {
  padding-block-end: 0 !important;
}
.tds-o-cannot-find-address {
  text-decoration: underline;
  border-block-start: 1px solid #D0D1D2;
  padding-block: 12px;
}
.edit-custom-address {
  padding-inline-start: var(--tds-size-4x);
}
.custom-address-caption-container {
  display: flex;
  padding-block: var(--tds-size-2x);
}
.custom-address-caption {
  padding-inline-start: var(--tds-size-base);
}
.accessories-shipping-form-container {
  display: flex;
}
.accessories-shipping-form {
  flex: 1;
}
.accessories-shipping-form.validation {
  visibility: hidden;
}
.loader-container {
  display: none;
}
.loader-container.is-loading {
  display: flex;
  flex: 1;
  margin: auto;
  margin-inline-start: -12px;
}
