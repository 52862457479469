.fade-feature {
  opacity: 0;
  transition: opacity ease-in 0.5s;
}
.fade-feature.is-open {
  opacity: 1;
}
.fade-feature.is-close {
  opacity: 0;
}
.tds-modal--is-open .fade-feature {
  display: none;
}
.scroll-indicator-container {
  position: absolute;
  z-index: 19;
  top: 96vh;
  right: 0;
  width: 416px;
  height: 4vh;
}
[dir=rtl] .scroll-indicator-container {
  left: 0;
  right: auto;
}
.scroll-indicator-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
}
.scroll-indicator {
  position: fixed;
  margin-inline-end: 196px;
  right: 0;
}
[dir=rtl] .scroll-indicator {
  right: auto;
  left: 0;
}
.tds-theme--replicant-v2 .scroll-indicator-container,
.tds-theme--enterprise .scroll-indicator-container {
  width: calc(432px - var(--tds-size-6x));
  right: var(--tds-size-6x);
}
.tds-theme--replicant-v2 .scroll-indicator,
.tds-theme--enterprise .scroll-indicator {
  margin-inline-end: 216px;
}
@media (max-width: 1025px) {
  .scroll-indicator {
    display: none;
  }
}
.bounce {
  animation: bounce 2s !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
  animation-iteration-count: infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
