.equal-width-table {
  table-layout: fixed;
  inline-size: 100%;
}
@media (max-width: 599px) {
  .equal-width-table {
    display: block;
  }
}
.collapsible-table {
  vertical-align: baseline !important;
}
.table-baseline {
  font-size: var(--tds-table-header-font_size);
}
@media (max-width: 599px) {
  .table-baseline {
    display: block;
    padding-block-end: 0 !important;
  }
}
