.gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  --off-pos-slide: var(--tds-size-3x);
}
.tds-theme--enterprise .gallery {
  --off-pos-slide: var(--tds-size-6x);
}
@media (max-width: 1024px) and (orientation: portrait) {
  .gallery {
    position: static;
    height: 56.8vw;
  }
  .coin-reloaded .cf-asset-wrapper--fixed .gallery {
    position: absolute;
  }
  .gallery__used-photos .gallery_asset--section {
    display: block;
  }
}
/* Mobile devices */
@media (max-width: 599px) {
  .cf-asset-wrapper--fixed .gallery {
    height: var(--gallery-fixed-height-mobile);
  }
  .gallery {
    --off-pos-slide: 0;
  }
}
.gallery_asset--section {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.gallery_asset--section img {
  width: 100%;
}
.gallery_control {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  display: block;
  background-color: transparent;
  border: 0;
  width: 10%;
  overflow: hidden;
  transition: background-color 300ms ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(var(--off-pos));
  animation: gallery-button-intro 5s var(--bezier) !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
  transition: opacity 0.5s ease, transform 0.5s var(--bezier);
}
.gallery_control .tds-icon-btn {
  background-color: var(--tds-color--grey65);
}
.gallery_control .gallery-icon-left {
  transform: rotate(-90deg);
}
[dir=rtl] .gallery_control .gallery-icon-left {
  transform: rotate(90deg);
}
.gallery_control .gallery-icon-right {
  transform: rotate(90deg);
}
[dir=rtl] .gallery_control .gallery-icon-right {
  transform: rotate(-90deg);
}
.gallery_control .gallery-icon-left,
.gallery_control .gallery-icon-right {
  background: var(--tds-color--grey60);
  border-radius: 100%;
  overflow: visible;
}
.gallery_control:focus {
  outline: none;
}
.gallery_control__prev {
  --off-pos: -16px;
  left: 0;
}
html[dir=rtl] .gallery_control__prev {
  right: 0;
  left: initial;
}
.gallery_control__next {
  --off-pos: 16px;
  right: 0;
}
@media (max-width: 1024px) and (orientation: landscape) {
  .gallery_control__next,
  .gallery_control__prev {
    display: none;
  }
  .asset-gallery-modal .gallery_control__next,
  .asset-gallery-modal .gallery_control__prev {
    display: flex;
  }
}
.asset-gallery-modal .gallery_control__expand {
  display: none;
}
.gallery_control__expand {
  position: absolute;
  z-index: 3;
  top: var(--tds-site-header--height);
  right: 0;
  height: 10%;
  border: 0;
  width: 10%;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation: none !important;
  display: none;
  transition: top -0.25s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.coin-reloaded.coin-order.has-top-banner:not(.has-top-banner--hidden) .gallery_control__expand {
  top: 108px;
}
html[dir=rtl] .gallery_control__next {
  left: 0;
  right: initial;
}
.gallery--dots-container {
  display: flex;
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  gap: 5px;
}
.gallery--dots-dot {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  opacity: 0.286;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  cursor: pointer;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  transition: all 300ms ease-in-out 0s;
  background-color: var(--tds-color--grey10);
  transform: scale(1);
  width: 8px;
  height: 8px;
}
.gallery--dots-dot__active {
  opacity: 1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}
.gallery--dots-dot__in-window {
  width: 8px;
  height: 8px;
  transform: scale(1);
}
.gallery--dots-dot__window-end-point:not(:first-child):not(:last-child) {
  transform: scale(0.5);
}
.gallery:hover .gallery_control, .gallery.focused .gallery_control {
  opacity: 1;
  transform: translateX(0);
}
.asset-gallery-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.asset-gallery-modal--close {
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  z-index: 10;
  right: 24px;
}
html[dir=rtl] .asset-gallery-modal--close {
  left: 24px;
  right: initial;
}
.asset-gallery-modal .gallery {
  height: 100vh;
}
.asset-gallery-modal .gallery--dots-container {
  bottom: 48px;
}
@media (min-width: 1025px) {
  .gallery.is-active .gallery_asset--section {
    transform: translateX(calc(-1 * var(--off-pos-slide)));
  }
  .gallery.is-active .gallery_asset--section.is-active ~ .gallery_asset--section {
    transform: translateX(var(--off-pos-slide));
  }
  .gallery.is-active ~ .gallery {
    transform: translateX(var(--off-pos-slide));
  }
}
@media (max-width: 1024px) {
  .gallery__used-photos .gallery_control {
    width: auto;
    padding: 8px;
    opacity: 1;
    transform: translateX(0);
  }
}
@media (max-width: 1024px) and (orientation: landscape) {
  .gallery_control__expand {
    display: flex;
  }
}
.gallery__used-photos .gallery_control .gallery-icon-left,
.gallery__used-photos .gallery_control .gallery-icon-right {
  background: #808080;
  opacity: 0.5;
}
.gallery__used-photos .gallery_control .gallery-icon-left:active,
.gallery__used-photos .gallery_control .gallery-icon-right:active {
  opacity: 1;
}
.gallery__used-photos .gallery_control .gallery-icon-left path,
.gallery__used-photos .gallery_control .gallery-icon-right path {
  stroke: white;
}
@keyframes gallery-button-intro {
  0% {
    opacity: 0;
    transform: translateX(var(--off-pos));
  }
  20% {
    opacity: 0;
    transform: translateX(var(--off-pos));
  }
  40% {
    opacity: 1;
    transform: translateX(0);
  }
  60% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(var(--off-pos));
  }
}
@keyframes gallery-asset-intro {
  to {
    transition: none;
  }
}
/* Gallery Animations */
.gallery {
  opacity: 0;
  visibility: hidden;
  transform: translateX(calc(-1 * var(--off-pos-slide)));
  transition: opacity 0.5s var(--bezier), visibility 0.5s 0.5s, transform 0.5s var(--bezier);
}
.gallery.is-active {
  opacity: 1;
  visibility: inherit;
  transform: translateX(0);
  transition: opacity 0.5s var(--bezier), visibility 0.5s 0s, transform 0.5s var(--bezier);
  height: 100%;
}

.gallery_asset--section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s var(--bezier), visibility 0.5s 0.5s, transform 0.5s var(--bezier);
}
.gallery_asset--section .group--main-content--title {
  position: absolute;
  bottom: 80px;
}
.gallery_asset--section.is-active {
  opacity: 1;
  visibility: inherit;
  animation: gallery-asset-intro 5s ease 0s !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: opacity 0.5s var(--bezier);
}
/* Only apply transitions when gallery is active, otherwise a slide could move
 * in the opposite direction of the gallery animation, cancelling it out & looking weird */
.gallery.is-active .gallery_asset--section.is-active {
  transform: translateX(0);
}

.group--main-content--feature-action {
  position: absolute;
  z-index: 3;
  bottom: var(--tds-size-2x);
  left: var(--tds-size-2x);
  display: flex;
  align-items: center;
  justify-content: center;
}
.group--main-content--feature-action .tds-btn--custom-feature {
  align-items: center;
  background-color: rgb(0 0 0 / 20%);
  color: var(--tds-text--contrast-low);
  display: inline-flex;
  font-family: var(--tds-font-family--combined);
  font-size: var(--tds-font-size-30);
  font-weight: var(--tds-font-weight--medium);
  justify-content: center;
  line-height: 1.2;
  block-size: var(--tds-size-3x);
  padding: var(--tds-size-half) var(--tds-size-base);
  position: relative;
  text-align: center;
  text-transform: inherit;
  transition: background-color .33s ease, color .33s ease;
}
