:root {
  --footer-shadow: #0000001A;
}
:root .tds-theme--dk {
  --footer-shadow: #ffffff1a;
}

.tds-shell-footer {
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
}
.tds-shell-footer .footer-action-block .tds-flex-item .summary-panel-terms {
  display: flex;
}
.tds-shell-footer .footer-action-block .tds-flex-item .summary-panel-terms .tds-flex-item {
  border-inline-start: none;
}
.tds-shell-footer .footer-action-block .tds-flex-item .summary-panel-terms .tds-flex-item:first-child {
  justify-content: flex-end;
}
.cf-footer-layout {
  width: 100vw;
}
.cf-footer--mobile {
  width: 100%;
}
.cf-footer-layout--desk.tds-layout-main--left {
  grid-template: "footer side" 1fr/9fr minmax(344px, 2fr);
  width: inherit;
}
@media (min-width: 600px) {
  .summary-panel-rate {
    flex-flow: column wrap;
  }
}
@media (min-width: 900px) {
  .cf-footer-layout {
    padding-left: 0;
  }
}
@media (max-width: 1024px) and (orientation: portrait) {
  .tds-shell-footer {
    left: 0;
    right: 0;
  }
  .tds-shell-footer .summary-panel--footer {
    margin: 0;
  }
  .coin-reloaded .aside-footer {
    width: 100vw !important;
  }
}
@media (min-width: 1025px) {
  .tds-shell-footer {
    width: 75%;
  }
  .coin-reloaded .aside-footer {
    width: 100%;
  }
}
html:not(.tds-theme--dk) body:not(.i18n-model-ct) .summary-panel--footer {
  background: var(--tds-color--grey70);
}
.summary-panel--footer {
  grid-area: footer;
  margin: auto;
  padding: 17px 20px;
  border-radius: var(--tds-size-2x) var(--tds-size-2x) 0px 0px;
  position: relative;
  transform: translateY(0);
  transition: transform 1s var(--bezier) 0s;
}
.summary-panel--footer .modal-trigger {
  outline: none;
  padding: 0;
}
.summary-panel--footer .tds-flex-item {
  display: flex;
  margin: 0 10px;
}
.summary-panel--footer .tds-flex-item.footer-action-block {
  height: 32px;
  margin: 0;
}
.summary-panel--footer .tds-flex-item .tds-flex-item:nth-child(2) {
  padding: 0 15px;
  border-inline-start: 1px solid var(--tds-color--grey50);
}
.i18n-model-ct .summary-panel--footer .tds-flex-item .tds-flex-item:nth-child(2) {
  border-inline-start: none;
}
.summary-panel--footer .action-trigger {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--tds-color--white);
  display: block;
  box-shadow: 0 var(--tds-size-half) var(--tds-size-half) 0 rgba(0, 0, 0, 0.03);
}
.summary-panel--footer .action-trigger .tds-icon {
  width: 15px;
  height: 15px;
  margin: 0 auto;
  display: block;
  background-size: contain;
  text-align: center;
  background-position: center 6px;
  background-repeat: no-repeat;
}
.summary-panel--footer .footer-action-block,
.summary-panel--footer .modal-trigger {
  width: 100%;
}
.summary-panel--footer .footer-action-block .modal-trigger {
  display: inline-flex;
}
.i18n-country-FR .summary-panel--footer {
  max-width: 780px;
}
.i18n-country-FR .summary-panel--footer .pricing-label--lease,
.i18n-country-FR .summary-panel--footer .pricing-label--loan {
  font-size: calc(var(--tds-base-font_size) - 3px);
}
.i18n-country-FR .summary-panel--footer .footer-disclaimer {
  font-size: calc(var(--tds-base-font_size) - 1px);
}
.summary-panel--item {
  display: flex;
  flex-flow: row nowrap;
}
.summary-panel--item .pricing-label {
  margin: 0 5px;
}
/* Fixes issue with dark theme not loading fast enough causing flashing white */
.tds-theme--dk .summary-panel--footer {
  background: var(--tds-color--grey10);
}
.tds-theme--dk .footer-modal-trigger {
  background: var(--tds-color--grey20);
}
/* Our tablet starts at 1024px for iPad portrait view */
@media (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
  .cf-footer-layout {
    padding: 0;
  }
  .cf-footer-layout .tds-layout-item:first-child:last-child {
    width: 100vw;
    margin: 0;
  }
  .summary-panel--footer {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    flex-flow: initial;
  }
  .summary-panel--footer .tds-flex-item {
    min-width: auto;
    flex: none;
    margin: 5px;
  }
  .summary-panel--footer .summary-options {
    margin: 0 auto;
  }
}
/* Mobile devices */
@media (max-width: 599px) {
  .cf-footer-layout {
    padding: 0;
  }
  .tds-theme--dk .summary-panel--footer {
    background: var(--tds-color--grey10) !important;
  }
  .footer-action-block .modal-trigger {
    margin-top: 1px;
  }
  .summary-panel--footer {
    flex-flow: row nowrap;
    padding: 16px;
  }
  .summary-panel--footer .tds-flex-item {
    display: flex;
    margin: 0 4px;
    flex: 100%;
    width: auto;
  }
  .summary-panel--footer .tds-flex-item.footer-action-block {
    height: 32px;
    margin: 0;
    min-width: auto;
    position: absolute;
    flex: inherit;
    max-width: 32px;
  }
  .summary-panel--footer .tds-flex-item .tds-flex-item:nth-child(2) {
    border-inline-start: none;
    padding: 0 5px;
  }
  [dir=rtl] .summary-panel--footer .tds-flex-item .tds-flex-item:nth-child(2) {
    border-inline-end: none;
    padding: 0 5px;
  }
  .summary-panel--footer .summary-options {
    flex-direction: column;
    align-items: center;
  }
  .summary-panel--footer .summary-options .summary-panel--item {
    flex-flow: row nowrap;
  }
  .i18n-region-eu .summary-panel--footer .summary-options .summary-panel--item {
    font-size: calc(var(--tds-size-base) + 2px);
  }
  .i18n-region-eu .summary-panel--footer .summary-options .summary-panel--item .finance-type {
    font-size: calc(var(--tds-size-2x) - 2px);
  }
  .summary-panel--footer .summary-panel-rate {
    flex-flow: row wrap;
  }
  .summary-panel--footer .summary-panel-rate .tds-text--medium {
    margin-right: 5px;
  }
}
.footer-action-link .modal-trigger {
  justify-content: center;
}
.footer-summary--disclaimer {
  justify-content: center;
  align-items: center;
}
/* TODO: remove custom style for CA */
.i18n-country-CA .summary-panel--item.beforeSavingsPrice {
  font-weight: 500;
  font-size: var(--tds-font-size-40);
  color: var(--tds-color-grey-10);
}

.tds-scrim--black .i18n-country-CA .summary-panel--item.beforeSavingsPrice {
  color: var(--tds-color--white);
}

.coin-reloaded .summary-panel--aside-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: calc(var(--tds-size-1x) + var(--tds-size-half)) var(--tds-size-3x);
  border-radius: 9px 9px 0px 0px;
  background-color: var(--tds-color--inverse);
  box-shadow: 0px -2px 8px 0 var(--footer-shadow);
  transition: transform 0.5s var(--tds-bezier) 0s, visibility 0s 0s;
  transform: translate3d(0, 0, 0);
}

.coin-reloaded .summary-panel--aside-footer__hide {
  transition: transform 0.5s var(--tds-bezier) 0s, visibility 0s 0.5s;
  transform: translate3d(0, 100%, 0);
  visibility: hidden;
}

.coin-reloaded .aside-footer {
  grid-area: aside;
  justify-self: center;
}

@media (max-width: 1024px) and (orientation: landscape) {
  .coin-reloaded .aside-footer {
    width: 374px;
    justify-self: right;
  }
}

.coin-reloaded .aside-footer--container {
  position: fixed;
  bottom: 0;
  z-index: 2;
  margin: 0;
  width: 495px;
  right: 0;
  grid-template: "main aside" 1fr / 9fr minmax(390px, 1fr) !important;
  grid-gap: 0 var(--tds-size-3x);
  padding-inline-end: var(--tds-size-3x);
}

[dir='rtl'] .coin-reloaded .aside-footer--container {
  left: 0;
  right: auto;
}

.tds-theme--enterprise .coin-reloaded .aside-footer--container {
  grid-template: "main aside" 1fr/9fr minmax(432px, 1fr) !important;
}

.coin-reloaded .aside-footer--button {
  inline-size: unset;
}

@media (max-width: 1024px) {
  .coin-reloaded .aside-footer--container {
    width: 100%;
    padding-inline-end: 0;
  }
}