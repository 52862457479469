.cf-modal-container {
  transition: opacity ease-in 0.3s;
  z-index: 21;
  opacity: 0;
  position: fixed;
}
.cf-modal-container.is-open {
  opacity: 1;
}
.cf-modal-container.is-close {
  opacity: 0;
}
@media (min-width: 600px) {
  body:not(.i18n-model-ct) .cf-modal-container .tds-modal {
    border-radius: var(--tds-size-2x) !important;
  }
}
.incentives-savings--summary {
  border-radius: var(--tds-size-2x);
  margin: 0 0 var(--tds-size-6x);
}
.incentives-savings--bar-chart {
  display: block;
  max-width: 500px;
  margin: var(--tds-size-3x) 0 0;
}
.incentives-savings--details {
  margin: var(--tds-size-4x) 0;
}
.incentives-savings--details summary .summary-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--tds-heading--color);
  font-family: var(--tds-font-combined--medium);
  font-size: var(--tds-font-size-50, 20px);
  font-weight: var(--tds-heading--font_weight);
  letter-spacing: -0.5px;
  line-height: var(--tds-font-size-55, 28px);
}
.incentives-savings--details summary::marker, .incentives-savings--details summary::-webkit-details-marker {
  display: none;
  content: "";
}
@media (min-width: 600px) {
  .incentives-savings--details summary .tds-modal {
    border-radius: var(--tds-size-2x) !important;
  }
  .incentives-savings--details summary .tds-modal[open] {
    transform: translateX(0) translateY(50%) !important;
  }
}
.incentives-savings--details summary .tds-icon {
  display: inline-block;
  width: 29px;
  height: 29px;
  transform: scale(1.7) translateY(-1px) rotateX(180deg);
  margin-left: var(--tds-size-half);
}
.incentives-savings--details [open] > summary {
  margin-bottom: var(--tds-size-2x);
}
.incentives-savings--details [open] > summary .tds-icon {
  transform: scale(1.7) translateY(-1px) rotateX(0deg);
}
.incentives-savings--disclaimer {
  --tds-text--color: var(--tds-color--grey30);
  color: var(--tds-text--color);
}
.incentives-savings--disclaimer p,
.incentives-savings--disclaimer .incentives-savings--disclaimer-text {
  font-size: var(--tds-font-size-20);
}
.incentives-savings--disclaimer h3 {
  font-size: var(--tds-font-size-30);
}
.incentives-description {
  color: var(--tds-text--color);
}
.incentives-description p {
  margin-bottom: var(--tds-size-2x);
}
.modal-container--finance_container .incentives-savings--line-items .line-item--value {
  float: right;
}
.modal-container--finance_container .incentives-savings--disclaimer .tds-o-tiny-table {
  margin-bottom: var(--tds-gutter);
}
.modal-container--finance_container .incentives-savings--disclaimer .tds-o-tiny-table th,
.modal-container--finance_container .incentives-savings--disclaimer .tds-o-tiny-table td {
  font-size: var(--tds-font-size-10);
  padding: 3px;
}
.modal-container--finance_container .incentives-savings--disclaimer .tds-o-tiny-table .tds-o-table-column-header {
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  .modal-container--finance_container .incentives-savings--disclaimer .tds-o-tiny-table .tds-o-table-column-header {
    display: none;
  }
}
@media (max-width: 600px) {
  .modal-container--save_design .share-design--container .share-modal .tds-btn {
    width: auto;
  }
}
.modal-container--save_design .share-design--container .share-design--shorturl-text {
  border: 0;
  background: transparent;
  resize: none;
  margin: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.modal-container--save_design .share-design--container .share-design--shorturl-text:focus {
  outline: none;
}
.modal-container--save_design .share-design--container .copy-link-btn {
  display: block;
}
.modal-container--save_design .share-design--container .share-modal--submit .save-design-updated-checkbox {
  margin-bottom: 0;
}
.modal-container--save_design .share-design--container .share-modal--submit .tds-form-item--choice {
  inline-size: auto;
}
@media (max-width: 600px) {
  .modal-container--save_design .share-design--container .share-modal--submit {
    display: unset;
  }
}
.modal-container--save_design .share-modal--success-block {
  display: flex;
  align-items: center;
}
.modal-container--save_design .share-modal--success-block .zoom {
  transform-origin: center; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
  animation: scaleAnim 0.5s ease-in-out !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
}
.modal-container--save_design .share-modal--success-block .icon-check-mark {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMjUgMGgyNXY1MEgwVjB6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZmlsbD0iIzAyRTM1OSIgZD0iTTI0LjA3IDMyLjMwOGwtNy4xNDctNy4xMzMgMS43ODctMS43ODMgNS4zNiA1LjM1IDkuNTI4LTkuNTExIDEuNzg3IDEuNzgzeiIvPjxnPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjxwYXRoIGQ9Ik0yNSAwQzExLjE5MyAwIDAgMTEuMTkzIDAgMjVzMTEuMTkzIDI1IDI1IDI1IDI1LTExLjE5MyAyNS0yNVMzOC44MDcgMCAyNSAwbTAgMi41YzEyLjQwNiAwIDIyLjUgMTAuMDk0IDIyLjUgMjIuNSAwIDEyLjQwNy0xMC4wOTQgMjIuNS0yMi41IDIyLjVTMi41IDM3LjQwNyAyLjUgMjVDMi41IDEyLjU5NCAxMi41OTQgMi41IDI1IDIuNSIgZmlsbD0iIzAyRTM1OSIgbWFzaz0idXJsKCNiKSIvPjwvZz48L2c+PC9zdmc+);
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.modal-container--save_design .share-modal--success-block .success-block--message {
  width: 200px;
}
.btn-fancy-submit--container {
  position: relative;
  height: 40px;
}
.btn-fancy-submit--container .loader-container {
  position: absolute;
  pointer-events: none;
  opacity: 0.4;
  left: calc(50% - 12px);
  top: 8px;
  height: 24px;
  width: 24px;
  z-index: 1;
  transform: translateX(-50%);
  margin: 8px;
}
.btn-fancy-submit--container .loader-container--loader {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: block;
  pointer-events: all;
}
.btn-fancy-submit--container .tsla-loader--container {
  margin: auto;
  display: flex;
}
.btn-fancy-submit--container .tsla-loader__spinner {
  animation: rotate 0.8s linear infinite !important; /** Remove !important when tds patches focus indication issue: AWSM-6069 */
  background: var(--tds-color--blue20);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: relative;
}
.btn-fancy-submit--container .tsla-loader__spinner:before,
.btn-fancy-submit--container .tsla-loader__spinner:after {
  content: "";
  position: absolute;
}
.btn-fancy-submit--container .tsla-loader__spinner:before {
  border-radius: 50%;
  background: linear-gradient(0deg, hsl(0, 0%, 100%) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%, linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%, linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%, linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}
.btn-fancy-submit--container .tsla-loader__spinner:after {
  background: var(--tds-color--blue30);
  border-radius: 50%;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tsla-icon {
  margin-right: 8px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.tsla-icon-link {
  display: inline-block;
  height: 15px;
  width: 20px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PCEtLUdlbmVyYXRvcjogU2tldGNoIDUwLjIgKDU1MDQ3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaC0tPjxwYXRoIGQ9Ik0yLjY2IDExLjI1YTIuMzI3IDIuMzI3IDAgMCAxIDAtMy4yODdMNC43OCA1Ljg0IDMuNzc0IDQuODM0IDEuNjUyIDYuOTU1YTMuNzUxIDMuNzUxIDAgMCAwIDAgNS4zMDMgMy43NTEgMy43NTEgMCAwIDAgNS4zMDMgMGwyLjEyMS0yLjEyMUw4LjA3IDkuMTI5bC0yLjEyMiAyLjEyMmEyLjMyNyAyLjMyNyAwIDAgMS0zLjI4OCAwem0yLjcwNC0xLjY0M2w0LjI0My00LjI0My0xLjA2MS0xLjA2LTQuMjQzIDQuMjQyIDEuMDYxIDEuMDZ6bTEuNTkxLTcuOTU1TDQuODM0IDMuNzczIDUuODQgNC43ODFsMi4xMjItMi4xMjJhMi4zMjcgMi4zMjcgMCAwIDEgMy4yODggMCAyLjMyNyAyLjMyNyAwIDAgMSAwIDMuMjg4TDkuMTI5IDguMDdsMS4wMDggMS4wMDcgMi4xMjEtMi4xMjFhMy43NTEgMy43NTEgMCAwIDAgMC01LjMwMyAzLjc1MSAzLjc1MSAwIDAgMC01LjMwMyAweiIgZmlsbD0iIzNFNkFFMSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+);
}
.tsla-icon-download {
  display: inline-block;
  height: 15px;
  width: 20px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjM2U2YWUyIj48cGF0aCBkPSJtNTEyIDQ3MmgtNTEydjQwaDUxMnoiLz48cGF0aCBkPSJtOTguNCAyNTYuNGMtMTAuNS0xMC41LTEwLjUtMjcuNCAwLTM3LjkgNS4zLTUuMyAxMi4xLTcuOSAxOC45LTcuOXMxMy43IDIuNiAxOC45IDcuOWw5MyA5M3YtMjgzLjdjMC0xNC43IDEyLTI2LjggMjYuOC0yNi44IDE0LjcgMCAyNi44IDEyIDI2LjggMjYuOHYyODMuN2w5Mi45LTkzYzEwLjUtMTAuNSAyNy40LTEwLjUgMzcuOSAwczEwLjUgMjcuNCAwIDM3LjlsLTEzOC43IDEzOC43Yy01IDUtMTEuOCA3LjktMTguOSA3LjlzLTEzLjktMi44LTE4LjktNy45eiIvPjwvZz48L3N2Zz4=);
}
.tds-flex[for=inspection-read-more] {
  align-items: center;
  flex-flow: row wrap;
}
.tds-flex[for=inspection-read-more] .tds-label-title {
  padding-inline-start: var(--tds-size-base);
}
.tds-modal-content .inspection-list.mechanical-inspections ul {
  padding-inline-start: var(--tds-size-5x);
}
.tds-modal-content .inspection-list.mechanical-inspections ul li {
  padding-inline-start: var(--tds-size-base);
}
.tds-modal-content .modal-container--feature-details ol {
  padding-inline-start: var(--tds-size-3x);
}
.tds-modal-content .modal-container--feature-details ol li {
  padding-inline-start: var(--tds-size-base);
}
.tds-modal-content .refurb-standards-description--container {
  margin-bottom: var(--tds-size-3x);
}
.tds-modal-content .refurb-standards-table {
  margin-block-start: var(--tds-size-2x);
}
.tds-modal-content .refurb-standards-table .refurb-standards-row {
  border-bottom: thin solid var(--tds-color--grey50);
}
.tds-modal-content .refurb-standards-table .inspection-label {
  min-width: 150px;
  padding-inline-end: var(--tds-size-5x);
}
.cosmetic-inspections ul {
  padding-inline-start: var(--tds-size-5x);
}
.cosmetic-inspections h6 {
  padding-inline-start: var(--tds-size-3x);
}
.i18n-country-AU .toggleType__container .toggleType-header.toggle-label {
  margin-top: var(--tds-size-base);
  margin-bottom: var(--tds-size-base);
}
.i18n-country-AU .toggleType__container .tds-legend {
  margin: 0 !important;
}
.i18n-country-AU .toggleType__container .toggleType__loanType {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
.i18n-country-AU .toggleType__container .toggleType__loanType .toggleType__item {
  display: flex;
  margin-block-end: var(--tds-size-base);
  width: -moz-fit-content;
  width: fit-content;
  margin-right: var(--tds-size-2x);
}
@media (max-width: 600px) {
  .i18n-country-AU .toggleType__container .toggleType__loanType {
    display: block;
  }
}
.modal-container--MODAL_FULLSCREEN {
  min-width: 100%;
  min-height: 100vh;
  display: block !important;
}
.cf-modal-container .tds-modal.modal-container--MODAL_FULLSCREEN {
  border-radius: 0 !important;
  transition: opacity 0.5s ease;
}
.feature-modal-link {
  text-align: start;
}
.feature-modal-link .action-trigger--btn-link {
  font-weight: 400;
}
.feature-modal-link .action-trigger--btn-link span {
  color: var(--tds-color--grey20);
}
.modal-container--preprod_modal .preprod-modal-description--container {
  margin-block-start: var(--tds-size-3x);
}
.modal-container--user_zip_code_modal,
.modal-container--delivery_zip_modal {
  height: auto !important;
}
.modal-container--inventory_compare_modal .tds-table-row td {
  padding-block: var(--tds-size-base);
  vertical-align: unset;
}
.modal-container--inventory_compare_modal .tds-modal-content {
  overflow: scroll;
}
.modal-container--inventory_compare_modal .compare-features-table {
  table-layout: fixed;
  width:fit-content;
}
.modal-container--inventory_compare_modal .compare-features-table-footer {
  margin-block-end: 96px;
}
.modal-container--inventory_compare_modal .compare-features-table .compare-img-container {
  vertical-align: baseline;
}
.modal-container--inventory_compare_modal .tds-switch_toggle-track:after {
  z-index: 9;
}
.modal-container--inventory_compare_modal .compare-modal-group1 {
  padding-inline-end: var(--tds-size-base);
  width: 50%;
}
.modal-container--inventory_compare_modal .compare-modal-group2 {
  padding-inline-start: var(--tds-size-base);
  width: 50%;
}
.modal-container--inventory_compare_modal .compare-title {
  padding: 8px 0 0;
}
.modal-container--inventory_compare_modal .compare-toggle-label {
  padding-top: var(--tds-size-half);
}
.modal-container--inventory_compare_modal .compare-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 35;
  padding: 24px 24px 48px;
  width: 100%;
  background: var(--tds-color--white);
}
.compare-img {
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
}
.compare-header {
  display: flex;
  justify-content: space-between;
}
.compare-img-item {
  max-width: -moz-min-content;
  max-width: min-content;
  min-width: 50%;
  margin-right: 0;
  margin-bottom: 0;
  text-align: center;
}
.modal-container--post_order_swap_error_modal .tds-modal-content {
  height: 100%;
}
.modal-container--post_order_swap_error_modal .modal-container--post-order-swap-error-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  padding: var(--tds-size-3x);
}
.modal-container--post_order_swap_error_modal .post-order-swap-error-btn {
  margin-block-start: var(--tds-size-2x);
  min-width: 175px;
}
.modal-container--post_order_swap_error_modal .swap-error-icon-status-warning {
  width: var(--tds-size-6x);
  height: var(--tds-size-6x);
}
@media (min-width: 600px) {
  .modal-container--vehicle_unavailable_modal .browse-other-cta {
    min-width: 320px;
  }
}
@media (max-width: 599px) {
  #tds-main-modal.modal-container--vehicle_unavailable_modal {
    height: unset !important;
  }
  .incentives-savings--disclaimer .tds-table-head {
    display: none;
  }
  .incentives-savings--disclaimer td {
    display: flex;
  }
  .incentives-savings--summary-table .tds-table-head th:nth-child(2),
  .incentives-savings--summary-table .tds-table-body td:nth-child(2),
  .incentives-savings--summary-table .tds-table-foot th:nth-child(2) {
    padding-right: var(--tds-size-base);
  }
  #REVIEW_ORDER_CONNECTIVITY .tds-table-row td,
  #REVIEW_ORDER_CONNECTIVITY .tds-table-row th {
    min-inline-size: 8ch;
  }
}
.tds-modal .tradein-car-image--container img {
  min-inline-size: 660px;
  max-inline-size: 660px;
  margin: 0 auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 600px) {
  .tds-modal .tradein-car-image--container img {
    min-inline-size: 345px;
    max-inline-size: 345px;
  }
}
.prequal-status-message--overflow-visible {
  overflow: visible;
}

.similar-designs--section {
  margin-block-end: var(--tds-form--gap);
}

.similar-designs--section .tds-form-input {
  min-width: 280px;
  max-width: 280px;
  min-height: 100%;
}

.similar-designs--section .similar-designs--card {
  min-height: 100%
}

.similar-designs--section .similar-designs--image {
  height: 120px;
  overflow: hidden;
}

.similar-designs--section .similar-designs--image .group--main-content--asset {
  margin-top: 10px;
}

.coin-option-diff--label {
  padding-block-start: var(--tds-form--gap);
  padding-block-end: var(--tds-size-1x);
}

.coin-preorderswap-entry-container:not(:empty) {
  padding-top: var(--tds-size-2x);
  padding-bottom: var(--tds-size-1x);
}

.similar-designs--section::-webkit-scrollbar {
  height: 0px;
}

.similar-designs--btn {
  position: sticky;
  z-index: 2;
  background-color: transparent;
  border: 0;
  width: 40px;
  min-width: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.similar-designs--btn--next {
  right: 0;
  margin-right:-20px;
}

.similar-designs--btn--next::before {
  content: " ";
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(to right, rgba(255,255,255,0.04) 0%, rgba(255,255,255,1) 100%);
}

.similar-designs--btn--previous {
  left: 0;
  margin-left: -20px;
}

.similar-designs--btn--previous::before {
  content: " ";
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(to left, rgba(255,255,255,0.04) 0%, rgba(255,255,255,1) 100%);
}

/* Compare models start */
.tds-modal.modal-container--COMPARE_MODELY_CARD {
    bottom: 0;
    /* This overrides another !important :( */
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
@media (min-width: 600px) {
  .tds-modal.modal-container--COMPARE_MODELY_CARD {
      transform: translateX(0) translateY(0);
  }
}
.compare-features--item .tds-list-o--unstyled {
  --tds-list-item--margin-block: var(--tds-size--2x);
  font-size: var(--tds-font-size-40);
}
.compare-features--item .tds-list-o--unstyled li {
  font-size: var(--tds-font-size-40);
  line-height: var(--tds-line-height-40);
}
.compare-models--container {
  margin-block-start: var(--tds-size-3x);
  margin-block-end: var(--tds-size-5x);
}
@media (max-width: 599px) {
  .compare-features--item .tds-list-o--unstyled li {
    font-size: var(--tds-font-size-30);
    line-height: var(--tds-line-height-30);
  }
  .tds-modal.modal-container--COMPARE_MODELY_CARD .option-widget--container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.text-loader--asset-container:has(.compare-modely-entry-point-container) {
  gap: var(--tds-size-2x);
  display: flex;
  flex-direction: row;
  border-radius: var(--tds-size-half);
  background: var(--tds-theme-background-container);
  padding: var(--tds-size-2x);
  padding-right: var(--tds-size-3x);
}
.text-loader--asset-container:has(.compare-modely-entry-point-container) .text-loader--asset {
  display: flex;
}
.modal-trigger-container:has(.compare-modely-entry-point-container),
.modal-trigger:has(.compare-modely-entry-point-container) {
  width: 100%;
}
.compare-modely-entry-point-container {
  width: 110px;
  height: 45px;
  border-top-left-radius: var(--tds-size-half);
  border-bottom-left-radius: var(--tds-size-half);
}
.compare-modely-entry-point-copy {
  height: 100%;
  display: flex;
}
.compare-modely-entry-point-copy span {
  align-self: center;
}
.text-loader--icon {
  display: flex;
  justify-content: flex-end;
  flex-grow: 2;
}
.text-loader--icon .compare-modely-entry-point-icon {
  align-self: center;
}
/* Compare models end */

