.legal-banner {
    display: none;
}

.i18n-country-NL .finplat-AUTO_LOAN-BALLOON_LOAN-CT_PRIVATE.legal-banner {
    display: block;
    width: 100%;
    padding-bottom: 6.9%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://digitalassets.tesla.com/co1n/image/upload/f_auto,q_auto,w_500/prod/static_assets/common/nl_finance_banner.jpg');
}